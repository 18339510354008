import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import MyAppBar from "./common/myAppBar";
import L from 'leaflet';
import 'leaflet-rotatedmarker';
import moment from "moment";
import navigation_2n from "../img/navigation_2n.png";
import navigation_2ngr from "../img/navigation_2ngr.png";
import navigation_no_dir from "../img/navigation_no_dir.png";
import navigation_no_dirbl from "../img/navigation_no_dirbl.png";
import navigation_off from "../img/navigation_off.png";
import navigation_stop from "../img/navigation_stop.png";
import navigation_red from "../img/navigation_red.png";
import {
    getActiveRoute,
    getDetailedRoutePlan,
    getVehicleTasks
} from "../services/routesService";
import logError from "../services/logService";
import {injectIntl} from 'react-intl';

@inject('rootStore')
@observer
class TaskPreview extends Component {

    intl = this.props.intl;
    store = this.props.rootStore.driverTableStore;
    truckId = this.props.match.params.id;

    routeId = this.props.match.params.routeId;
    routePointId = this.props.match.params.routePointId;

    truck;
    waypIndex = 0;

    deviceOnMap;

    getMessage = (id) => {
        return this.intl.formatMessage({id: id}) + " ";
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        if (this.store.allTrucks.length === 0) {
            this.store.loadVehicles()
                .then(
                    () => {
                        this.truck = this.store.getVehicle(this.truckId);
                        this.initMap();
                        this.loadVehicleTasks();

                    }
                );
        } else {
            this.initMap();
            this.loadVehicleTasks();
        }
    }

    loadVehicleTasks = async () => {
        let tasks = await getVehicleTasks(this.routeId);
        let detailedRoute = await getDetailedRoutePlan(this.routeId);
        let taskData;
        let detailedRouteData;

        if (detailedRoute.data && tasks.data) {
            taskData = tasks.data;
            detailedRouteData = detailedRoute.data;
        } else {
            console.log("loadMapData() no taskData " + this.truckId);
            logError("loadMapData() no taskData " + this.truckId);
            return;
        }

        let doneTasks = taskData.doneTasks;
        let futureTasks = taskData.futureTasks;
        let taskInProgress = taskData.taskInProgress;


        let allTasks = doneTasks.concat(futureTasks);

        if (taskInProgress != null)
            allTasks.push(taskInProgress);

        const task = allTasks.filter(task => task.position.id == this.routePointId);

        this.showDetailedRoute(detailedRouteData);
        this.showWaypoints(detailedRouteData, this.truck.deviceId, null);
        this.showTaskRoute(task[0]);

        this.map.panTo(new L.LatLng(parseFloat(task[0].position.latitude), parseFloat(task[0].position.longitude)));

        this.addDevice();

    }

    addDevice = () => {
        let deviceId = this.store.getVehicle(this.truckId).deviceId;
        let data = this.store.getVehicle(this.truckId).eventsContainer.driverState.position;

        try {
            if (!data) {
                logError('addDevice, no positions. device: ' + deviceId);
            }
            if (!data.heading) {
                data.heading = 0;
            }

            let pictureName = navigation_2ngr;
            if (data.ignitionState == 'ON') {
                if (data.appData)
                    pictureName = navigation_2n;

                if (data.speed == 0 && !data.appData)
                    pictureName = navigation_no_dir;
                else if (data.speed == 0)
                    pictureName = navigation_no_dirbl;
            } else {
                pictureName = navigation_off;
                if (data.lastIgnitionChange && moment(new Date()).diff(moment(data.lastIgnitionChange), 'minutes') > 15) {
                    pictureName = navigation_stop;
                }
            }

            if (moment(new Date()).diff(moment(data.datTim), 'minutes') > 30) {
                pictureName = navigation_red;
            }

            let greenIcon = L.divIcon({
                className: 'dev' + data.deviceId,
                html: '<div style="background:transparent;" id="dev' + data.deviceId + '" class="ui-widget">' +
                    '<img src="' + pictureName + '" width="26" /></div>',
                iconAnchor: [13, 13],
                iconSize: [26, 26],

            });

            let locationx = new L.LatLng(parseFloat(data.latit), parseFloat(data.longit));
            let toolt = new L.tooltip({
                interactive: true,
                permanent: true,
                className: 'device',
                direction: 'auto'
            });

            if (this.deviceOnMap != null) {
                this.map.removeLayer(this.deviceOnMap);
            }
            this.deviceOnMap = L.marker(locationx, {
                icon: greenIcon,
                rotationAngle: data.heading
            }).addTo(this.map);

        } catch (e) {
            console.log(e);
            logError('addDevice: ' + e);
        }

    }

    showTaskRoute = (data) => {

        let i, poylines = {}, timeTour;

        if (data.detailedRoute) {

            //Route stiles
            let styles = [{color: 'black', opacity: 0.15, weight: 11},
                {color: 'white', opacity: 0.8, weight: 8},
                {color: 'yellow', opacity: 1, weight: 4}];

            for (i = 0; i < 3; i++) {
                poylines[i] = L.geoJSON(data.detailedRoute, styles[i]);
                poylines[i].addTo(this.map);
            }

        }
    }


    showDetailedRoute = (data) => {

        let i, poylines = {}, timeTour;

        try {
            // Planed Route
            if (data.polyline) {

                //Route stiles
                let styles = [{color: 'black', opacity: 0.15, weight: 11},
                    {color: 'white', opacity: 0.8, weight: 8},
                    {color: 'red', opacity: 1, weight: 4}];

                for (i = 0; i < 3; i++) {
                    poylines[data.polyId + '' + i] = L.geoJSON(JSON.parse(data.polyline), styles[i]);
                    poylines[data.polyId + '' + i].addTo(this.map);
                }
            }

            //Driver route
            if (data.polylineD) {

                //Driver Route stiles
                let stylesD = [{
                    dashArray: '5 10',
                    color: 'black',
                    opacity: 0.15,
                    weight: 8
                },
                    {
                        dashArray: '5 10',
                        color: 'white',
                        opacity: 0.8,
                        weight: 6
                    },
                    {
                        dashArray: '5 10',
                        color: '#0066FF',
                        opacity: 1,
                        weight: 4
                    }];

                for (i = 0; i < 3; i++) {
                    poylines[data.polyId + 'D' + i] = L.geoJSON(JSON.parse(data.polylineD), stylesD[i]);
                    poylines[data.polyId + 'D' + i].addTo(this.map);
                }

            }

            //Navigation route
            if (data.polylineN) {

                //Navig Route stiles
                let stylesN = [{color: 'black', opacity: 0.15, weight: 11},
                    {color: 'white', opacity: 0.8, weight: 8},
                    {
                        dashArray: '0 50',
                        color: '#003300',
                        opacity: 1,
                        weight: 10
                    }];

                let mascN = [];

                for (i = 2; i < 3; i++) {
                    poylines[data.polyId + 'N' + i] = L.geoJSON(JSON.parse(data.polylineN), stylesN[i]);
                    poylines[data.polyId + 'N' + i].addTo(this.map);
                }


            }

            if (timeTour) {
                timeTour.bringPolysToFront();
                if (poylines[data.polyId + 'N' + 2])
                    poylines[data.polyId + 'N' + 2].bringToFront();
            }

        } catch (e) {
            console.log(e);
            logError(e);
        }
    }

    showWaypoints = (content, deviceId, routePlanInfo) => {
        try {

            let data = JSON.parse(content.waypoints);

            let detailedRouteMarkers, routeMonitorMarkers = [];
            let timeTour = null;
            this.waypIndex = 0;

            data.map((wp, index) => {

                let device = false;
                let clientPlace = null;

                if (wp.device) {
                    device = {
                        lat: "" + wp.lat + "",
                        lng: "" + wp.lng + "",
                        devId: "" + wp.device.deviceId + "",
                        angle: "" + 0 + "",
                        devName: "" + wp.device.deviceName + ""
                    };
                }
                if (wp.clientPlace) {
                    clientPlace = wp.clientPlace;
                }

                let wps = {
                    latLng: L.latLng(wp.lat, wp.lng),
                    options: {allowUTurn: false},
                    device: device,
                    type: wp.type,
                    clientPlace: clientPlace,
                    routePointId: wp.id,
                    startDate: wp.visited,
                    queue: wp.queue,
                    markerColor: this.assignMarkerColor(wp, routePlanInfo)
                };

                if (wps.options) {
                    wps.viaTypeEnum = wp.options.viaTypeEnum;
                    wps.linkTypeEnum = wp.options.linkTypeEnum;
                    wps.radius = wp.options.radius;
                    wps.open = wp.options.open;
                    wps.close = wp.options.close;
                    wps.service = wp.options.service;
                    wps.forceViolations = wp.options.forceViolations;
                    wps.useMainRoads = wp.options.useMainRoads;
                    wps.inaccurate = wp.options.inaccurate;
                }

                if (wp.type == 12 && wp.autoService) {
                    wps.pointData = wp.autoService;
                }

                if (wp.type == 13 && wp.commonPlace) {
                    wps.pointData = wp.commonPlace;
                }

                if (wp.html)
                    wp.html = wp.html;
                if (data && deviceId !== null) {
                    if (index === data.length - 1) {
                        routeMonitorMarkers.push(this.createMark(index, wps, false, true, null, wps.markerColor));
                        let last = routeMonitorMarkers[routeMonitorMarkers.length - 1];
                        let toolt = new L.tooltip({
                            interactive: false,
                            permanent: true,
                            className: 'mark',
                            direction: 'auto'
                        });
                        toolt.setContent('<span><i class="fa fa-flag"></i></span>');
                        if (last) {
                            last.bindTooltip(toolt).openTooltip().addTo(this.map);
                        }
                    } else {
                        routeMonitorMarkers.push(this.createMark(index, wps, false, false, null, wps.markerColor));
                    }
                } else {
                    if (index === data.length - 1) {
                        detailedRouteMarkers.push(this.createMark(index, wps, false, true, content.polyId, wps.markerColor));
                        let polyTr = typeof content.polyline !== 'undefined' ? 'ui-icon-check' : 'ui-icon-blank';
                        let polyD = typeof content.polylineD !== 'undefined' ? 'ui-icon-check' : 'ui-icon-blank';
                        let polyN = typeof content.polylineN !== 'undefined' ? 'ui-icon-check' : 'ui-icon-blank';
                        let prognoz = timeTour !== null ? 'ui-icon-check' : 'ui-icon-blank';
                        let last = detailedRouteMarkers[data.length - 1];
                        let routePlanId = content.polyId.replace('segm', '');
                        let toolt = new L.tooltip({
                            interactive: true,
                            permanent: true,
                            className: 'mark',
                            direction: 'auto'
                        });
                        toolt.setContent(
                            '<div style="font-size:0.9em; font-weight:bold;" onclick="if(window.event)window.event.stopPropagation()"> <i onclick="removeDetailedRoute(\'' + content.polyId + '\', true);if(timeTour)timeTour.reset();timeTour=null; map.closePopup();" style="font-size: 1.8em; vertical-align:top;" class=\"fa fa-times-circle-o\"></i> <div style="display:inline-block" onmouseenter="expandCloseRoute($(this));" onmouseleave="colapseCloseRoute($(this));"><span class="span-ckb" ><div class="ui-chkbox ui-widget"> <div class="ui-helper-hidden-accessible"><input type="checkbox"/></div><div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default span-ckb"><span class="ui-chkbox-icon ui-icon ui-c ' + polyTr + '" onclick="$(this).toggleClass(\'ui-icon-check\');$(this).toggleClass(\'ui-icon-blank\');togglePolyline(\'' + content.polyId + '\')"></span></div></div> </span> <span class="span-ckb red"> Užduota </span> <span  style="margin-left:2px; background-color:white;" class="popPolExp"><i class="fa fa-flag"></i></span> ' +
                            '<div><span class="span-ckb"><div class="ui-chkbox ui-widget"><div class="ui-helper-hidden-accessible"><input type="checkbox"/></div><div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default "><span class="ui-chkbox-icon ui-icon ui-c ' + polyD + '" onclick="$(this).toggleClass(\'ui-icon-check\');$(this).toggleClass(\'ui-icon-blank\');togglePolyline(\'' + content.polyId + 'D\')"></span></div></div> </span> <span class="span-ckb blue">applicationShowWaypointsPassed </span> </div>' +
                            '<div><span class="span-ckb"><div class="ui-chkbox ui-widget"><div class="ui-helper-hidden-accessible"><input type="checkbox"/></div><div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default "><span class="ui-chkbox-icon ui-icon ui-c ' + polyN + '" onclick="$(this).toggleClass(\'ui-icon-check\');$(this).toggleClass(\'ui-icon-blank\');togglePolyline(\'' + content.polyId + 'N\')"></span></div></div> </span> <span class="span-ckb green"> applicationShowWaypointsNavigation </span></div>' +
                            '<div><span class="span-ckb"><div class="ui-chkbox ui-widget"><div class="ui-helper-hidden-accessible"><input type="checkbox"/></div><div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default "><span class="ui-chkbox-icon ui-icon ui-c ' + prognoz + '" onclick="$(this).toggleClass(\'ui-icon-check\');$(this).toggleClass(\'ui-icon-blank\');if(timeTour)timeTour.toggle();"></span></div></div> </span> <span class="span-ckb"> applicationShowWaypointsForecast </span></div>' +
                            '<div><span class="span-ckb"><div class="ui-chkbox ui-widget"><div class="ui-helper-hidden-accessible"></div><a onclick="removeDetailedRoute(\'' + content.polyId + '\', true);if(timeTour)timeTour.reset();timeTour=null;map.closePopup(); planExistingRoute([{name: \'routePlanId\', value: \'' + routePlanId + '\' }]); ">applicationShowWaypointsEdit</a></div> </span></div></div>  </div>'
                        );
                        if (last)
                            last.bindTooltip(toolt).openTooltip().addTo(this.map);
                    } else {
                        detailedRouteMarkers.push(this.createMark(index, wps, false, false, content.polyId, wps.markerColor));
                    }
                }
            });


            if (!data) {
                let group = new L.featureGroup(detailedRouteMarkers);
                this.map.fitBounds(group.getBounds());
            }
        } catch (e) {
            console.log(e);
            logError('showWayPoints ' + e);
        }
    }

    createMark = (i, wp, history, last, polyId, markerColor, dontUseQueue) => {

        let wpCoords = this.createCoordinatesHtml(wp.latLng.lat, wp.latLng.lng);
        // let indexCreateMarkCoords = this.intl.formatMessage({ id: 'indexCreateMarkCoords' }) + " ";
        let indexCreateMarkCoords = this.getMessage('indexCreateMarkCoords') + " ";

        let isClient = false;
        try {
            let marker;
            if (!wp.device) {
                let tarpinis = wp.type === 6;
                let html = "";
                let workingHours = "";

                if (wp.type === 0 || wp.type === 1) {
                    let polystr = "";
                    if (!history && polyId) {
                        polystr = ",'" + polyId + "'";
                    }


                    if (wp.html) {
                        workingHours += this.createWorkingHoursPopupForm(wp.html, i);
                    }
                    html += " <input id=\"clientId\" type=\"hidden\" value=\"" + wp.clientPlace.id + "\" /> " +
                        "<input id=\"routePointId\" type=\"hidden\" value=\"" + wp.routePointId + "\" /> " +
                        "<i class=\"fa fa-globe\"></i> " + this.getMessage('indexCreateMarkCoords') + wp.latLng.lat + ", " + wp.latLng.lng +
                        "<br/>" + "<i class=\"fa fa-building\"></i> " + this.getMessage('indexCreateMarkTitle') + wp.clientPlace.pavad +
                        "<br/>" + "<i class=\"fa fa-map-marker\"></i> " + this.getMessage('indexCreateMarkAddress') + wp.clientPlace.adr +
                        "<br/>" + "<i class=\"fa fa-map-marker\"></i> " + this.getMessage('indexCreateMarkPostCode') + wp.clientPlace.pindex +
                        "<br/>" + "<i class=\"fa fa-map-marker\"></i> " + this.getMessage('indexCreateMarkCity') + (wp.clientPlace.miestas || "") +
                        "<br/>" + "<i class=\"fa fa-map-marker\"></i> " + this.getMessage('indexCreateMarkCountry') + (wp.clientPlace.country != null ? (wp.clientPlace.country.pavad1 || "") : "") +
                        "<br/>" + (wp.clientPlace.fiksuotas === true ?
                                "<span class=\"fixSp\" style=\"color:#00AB00;\"><i class=\"fa fa-check-circle-o\"></i> " + this.getMessage('indexCreateMarkFixed') + "</span > "
                                : "<span class=\"fixSp\" style=\"color:red;\"><i class=\"fa fa-times-circle-o\"></i> " + this.getMessage('indexCreateMarkNotFixed') + "</span>"
                        )
                    ;
                    if (wp.position) {
                        html += " <i class=\"fa fa-calendar-o\"></i> " + moment(wp.position.datTim).format("YYYY-MM-DD HH:mm");
                    }
                    // html += workingHours;
                    // html += " <div><a href=\"#\"  class='" + (isClient == 'true' ? 'hide' : '') + "' onclick=\"showPointStops([{name:'id', value:'" + wp.routePointId + "'}])\"> <i class=\"fa fa-stop\" style=\"color:black;\"></i>" + this.getMessage('indexCreateMarkStopsAnalysis') + "</a> <a  href=\"#\" style=\"margin-left:15px;\" class='" + (isClient == 'true' ? 'hide' : '') + "' onclick=\"createGateIcon(" + wp.clientPlace.id + "," + wp.routePointId + "," + history + polystr + ")\">  <i class=\"fa fa-gavel\" style=\"color:black;\"></i>indexCreateMarkGateFixation </a> </div>" +
                    //     " <div style=\"margin-top:3px;\"> <a  href=\"#\"  class='" + (isClient == 'true' ? 'hide' : '') + "' onclick=\"showClient([{name:'id', value: '" + wp.clientPlace.id + "'}])\">  <i class=\"fa fa-search\" style=\"color:black;\"></i> indexCreateMarkMore</a> <a  href=\"#\" style='" + (isClient == 'true' ? 'margin-left:0px;' : 'margin-left:80px;') + "'  onclick=\"map.setView(new L.LatLng(" + wp.latLng.lat + "," + wp.latLng.lng + "), 14);\">  <i class=\"fa fa-search-plus\" style=\"color:black;\"></i> indexCreateMarkZoomIn </a> </div>";
                }
                if (wp.type === 0) {
                    html = "<h3>" + this.getMessage('indexCreateMarkLoading') + "</h3>" + html;
                } else if (wp.type === 1) {
                    html = "<h3>" + this.getMessage('indexCreateMarkUnLoading') + "</h3>" + html;
                } else if (wp.type === 2) {
                    html += "<h3>" + this.getMessage('indexCreateMarkIntermediatePoint') + "</h3> <i class=\"fa fa-globe\"></i>" + this.getMessage('indexCreateMarkCoords') + wp.latLng.lat + ", " + wp.latLng.lng + "<br/>" + '<span style="display: inline-block;vertical-align:top;"><i class="fa fa-home"></i></span> <span style="max-width: 220px;display: inline-block;" class="adr">' + this.getMessage('indexCreateMarkAddress') + '</span>';
                } else if (wp.type === 3) {
                    html += this.createGasStationPopupContent(wp, i);
                } else if (wp.type === 4) {
                    html += this.createParkingPopupContent(wp, i);
                } else if (wp.type === 7) {
                    html += "<h3>" + this.getMessage('indexCreateMarkChange') + "  <span class=\"fa-stack fa-lg\" > <i class=\"fa fa-circle-thin fa-stack-2x\"></i> <i class=\"fa fa-refresh fa-stack-1x\"></i></span></i></h3> <i class=\"fa fa-globe\"></i>" + this.getMessage('indexCreateMarkCoords') + wp.latLng.lat + ", " + wp.latLng.lng;
                } else if (wp.type === 8) {
                    html += this.createFerryPopupContent(wp);
                } else if (wp.type === 9) {
                    html += this.createTrainPopupContent(wp);
                } else if (wp.type === 10) {
                    html += this.createFerryDestinationPopupContent(wp);
                } else if (wp.type === 11) {
                    html += this.createTrainDestinationPopupContent(wp);
                } else if (wp.type == 12 && wp.pointData) {
                    html += '<span style="display: inline-block;vertical-align:top;"><i class="fa fa-comment-o"></i></span> <span style="max-width: 220px;display: inline-block;" class="adr"> ' + this.getMessage('indexCreateMarkTitle') + ' ' + wp.pointData.name + '</span><br />';
                    html += '<span style="display: inline-block;vertical-align:top;"><i class="fa fa-globe"></i></span> <span style="max-width: 220px;display: inline-block;" class="adr">' + this.getMessage('indexCreateMarkCoords') + wp.latLng.lat + ", " + wp.latLng.lng + '</span><br/>';
                    if (wp.html) {
                        workingHours += this.createWorkingHoursPopupForm(wp.html, i);
                    }
                    html += workingHours;
                } else if (wp.type == 13 && wp.pointData) {
                    html += '<span style="display: inline-block;vertical-align:top; color:#' + wp.pointData.categoryColor + '"><i class="' + wp.pointData.categoryIcon + '"></i></span> <span style="max-width: 220px;display: inline-block; color:#' + wp.pointData.categoryColor + '" class="adr">' + wp.pointData.categoryName + '</span><br />';
                    html += '<span style="display: inline-block;vertical-align:top;"><i class="fa fa-comment-o"></i></span> <span style="max-width: 220px;display: inline-block;" class="adr">indexCreateMarkTitle ' + wp.pointData.name + '</span><br />';
                    html += '<span style="display: inline-block;vertical-align:top;"><i class="fa fa-globe"></i></span> <span style="max-width: 220px;display: inline-block;" class="adr">' + this.getMessage('indexCreateMarkCoords') + wp.latLng.lat + ", " + wp.latLng.lng + '</span><br/>';
                    html += '<span style="display: inline-block;vertical-align:top;"><i class="fa fa-home"></i></span> <span style="max-width: 220px;display: inline-block;" class="adr">' + this.getMessage('indexCreateMarkAddress') + wp.address + '</span><br/>';
                    if (wp.html) {
                        workingHours += this.createWorkingHoursPopupForm(wp.html, i);
                    }
                    html += workingHours;
                } else if (wp.type === 14) {
                    html += this.createBorderPopupContent(wp);
                } else if (wp.type === 15) {
                    html += this.createBorderDestinationPopupContent(wp);
                }

                if (!tarpinis) {
                    let exclass = '';
                    if (wp.type === 0 || wp.type === 1)
                        exclass = 'history' + wp.clientPlace.id;

                    let color = this.notEmpty(markerColor) ? markerColor : 'blue';


                    let icon = L.ExtraMarkers.icon({
                        icon: this.getMarkerIconByWaypointType(wp.type, wp.pointData),
                        markerColor: color,
                        shape: 'circle',
                        prefix: 'fa',
                        extraClasses: exclass
                    });

                    if (!wp.inaccurate) {
                        marker = L.marker(wp.latLng, {
                            draggable: false,
                            icon: icon
                        });
                    } else {
                        marker = L.marker(wp.latLng, {
                            draggable: false,
                            icon: icon
                        });
                    }

                    this.setupTooltip(marker, wp, last, dontUseQueue);

                    marker.desc = html;
                    if (wp.html) {
                        marker.html = wp.html;
                        marker.index = i;
                        marker.changeSave = true;
                    }

                    marker.routePointId = wp.routePointId;
                    marker.addTo(this.map);

                    // this.oms.addMarker(marker);
                    this.waypIndex++;

                    let popup = new L.Popup({offset: [0, -20]});
                    // historyRadiusSearch(marker);
                    popup.setContent(marker.desc);
                    popup.setLatLng(marker.getLatLng());
                    marker.bindPopup(popup);


                } else {
                    html = "";

                    let radius, useMainRoads;
                    if (wp.radius)
                        radius = wp.radius;
                    else
                        // radius = $('#settings\\:viatyperadius').val();

                    if (wp.useMainRoads == null) {
                    }
                    // useMainRoads = $('#settings\\:mainRoads_input').parent().next().hasClass('ui-state-active');
                    else
                        useMainRoads = wp.useMainRoads;


                    html += '<h3>' + this.getMessage('indexCreateMarkRedirectPoint') + '</h3>'
                        + wpCoords + '<br/> ' +
                        '<table><tr><td><span>' + this.getMessage('indexCreateMarkPriorityHighWays') + '</span></td><td><div class="ui-chkbox ui-widget"><div class="ui-helper-hidden-accessible"><input type="checkbox"  /></div><div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default "><span class="ui-chkbox-icon ui-icon ui-c ' + (useMainRoads === true ? 'ui-icon-check' : 'ui-icon-blank') + '" ></span></div></div> </td></tr>' +
                        '<tr><td><span class="red">' + this.getMessage('indexCreateMarkIgnoreRestrictions') + '<br/>' + this.getMessage('indexCreateMarkNextDistance') + '  </span></td><td><div class="ui-chkbox ui-widget"><div class="ui-helper-hidden-accessible"><input type="checkbox"  /></div><div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default "><span class="ui-chkbox-icon ui-icon ui-c ' + (wp.forceViolations === true ? 'ui-icon-check' : 'ui-icon-blank') + '" ></span></div></div> </td></tr>' +
                        '<tr><td>' + this.getMessage('indexCreateMarkRadius') + ' </td><td><input  style="padding: 1px; max-width: 50px;" value="' + radius + '" id="plnPtRadius" class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" type="text"  role="textbox"  />' + this.getMessage('indexCreateMarkMeters') + '  </td></tr></table>';

                    let color = 'blue';
                    if (this.notEmpty(markerColor)) {
                        color = markerColor;
                    }
                    let icon = L.divIcon({
                        className: 'tarpinis',
                        html: '<i class="fa fa-circle-o-notch"></i>',
                        markerColor: color
                    });

                    marker = L.marker(wp.latLng, {
                        draggable: false,
                        icon: icon
                    }).bindPopup(html);
                }
            } else {
                if (!wp.device.angle) {
                    wp.device.angle = 0;
                }

                this.waypIndex++;

                let color = '#106CB7';
                if (this.notEmpty(markerColor)) {
                    color = markerColor;
                }

                let icon = L.divIcon({
                    className: 'truck-div',
                    html: '<div style="background:transparent;" class="ui-widget">' +
                        '<span style="color:' + color + '; font-size:1em;" class="fa-stack fa-lg"><i class="fa fa-circle fa-stack-2x"></i> <i class="fa-stack-1x" style="color:white;">pr</i></span></div>',
                    iconSize: [26, 26]
                });

                let startDate = "";

                if (wp.startDate)
                    startDate = '<i class="fa fa-circle-thin"></i><i class="fa fa-long-arrow-right"></i> Pradėjo:' + moment(wp.startDate).format("YYYY-MM-DD HH:mm");
                if (wp.position)
                    startDate = '<i class="fa fa-circle-thin"></i><i class="fa fa-long-arrow-right"></i> Pradėjo:' + moment(wp.position.datTim).format("YYYY-MM-DD HH:mm");
                let locationx = new L.LatLng(parseFloat(wp.device.lat), parseFloat(wp.device.lng));
                marker = L.marker(locationx, {
                    icon: icon,
                    rotationAngle: wp.device.angle
                })
                    .bindPopup('<h3>' + this.getMessage('indexCreateMarkBegin') + '</h3>'
                        + wpCoords
                    );

                let markerPopup = marker.getPopup();
                if (markerPopup) {
                    markerPopup['marker'] = marker;
                }

                let html = '<h3>' + this.getMessage('indexCreateMarkBegin') + '</h3>'
                    + wpCoords + "<br/>" + startDate + " <div>";

                marker.bindPopup(html)
            }

            marker.type = wp.type;
            marker.addTo(this.map);

            return marker;
        } catch (e) {
            console.log(e);
            logError(e);
            return false;
        }
    }


    createWorkingHoursPopupForm = (waypointHtml, index) => {
        return '<form id="' + waypointHtml + index + '" name="popupform' + index + '" method="post" action="/" enctype="application/x-www-form-urlencoded">' +
            '<input type="hidden" name="popupform' + waypointHtml + index + '" value="popupform' + waypointHtml + index + '"></form>';
    }

    createGasStationPopupContent = (waypoint, index) => {
        let popupContent = '<h3>' + this.getMessage('indexCreateMarkGasStation') + '  <i class="fa fa-tint"></i></h3>'
            + this.createCoordinatesHtml(waypoint.latLng.lat, waypoint.latLng.lng);
        if (waypoint.html) {
            popupContent += this.createWorkingHoursPopupForm(waypoint.html, index);
        }
        return popupContent;
    }

    createParkingPopupContent = (waypoint, index) => {
        let popupContent = '<h3>indexCreateMarkParking <i class="fa fa-product-hunt"></i></h3>'
            + this.createCoordinatesHtml(waypoint.latLng.lat, waypoint.latLng.lng);
        if (waypoint.html) {
            popupContent += this.createWorkingHoursPopupForm(waypoint.html, index);
        }
        return popupContent;
    }

    createFerryPopupContent = (waypoint) => {
        return '<h3>indexCreateMark.ferry.title</h3>'
            + this.createCoordinatesHtml(waypoint.latLng.lat, waypoint.latLng.lng);
    }

    createTrainPopupContent = (waypoint) => {
        return '<h3>indexCreateMark.train.title</h3>'
            + this.createCoordinatesHtml(waypoint.latLng.lat, waypoint.latLng.lng);
    }

    createFerryDestinationPopupContent = (waypoint) => {
        return '<h3>indexCreateMark.ferry.destination.title</h3>'
            + this.createCoordinatesHtml(waypoint.latLng.lat, waypoint.latLng.lng);
    }

    createTrainDestinationPopupContent = (waypoint) => {
        return '<h3>indexCreateMark.train.destination.title</h3>'
            + this.createCoordinatesHtml(waypoint.latLng.lat, waypoint.latLng.lng);
    }

    createBorderPopupContent = (waypoint) => {
        let popupContent = '<h3>indexCreateMark.border.title</h3>'
            + this.createCoordinatesHtml(waypoint.latLng.lat, waypoint.latLng.lng);

        return popupContent;
    }

    createBorderDestinationPopupContent = (waypoint) => {
        let popupContent = '<h3>indexCreateMark.border.destination.title</h3>'
            + this.createCoordinatesHtml(waypoint.latLng.lat, waypoint.latLng.lng);

        return popupContent;
    }

    createWorkingHoursPopupForm = (waypointHtml, index) => {
        return '<form id="' + waypointHtml + index + '" name="popupform' + index + '" method="post" action="/" enctype="application/x-www-form-urlencoded">' +
            '<input type="hidden" name="popupform' + waypointHtml + index + '" value="popupform' + waypointHtml + index + '"></form>';
    }

    createCoordinatesHtml = (latitude, longitude) => {
        return '<i class="fa fa-globe"></i>' + this.getMessage('indexCreateMarkCoords') + latitude + ', ' + longitude;
    }


    assignMarkerColor = (waypoint, routePlanInfo) => {
        let color = 'blue';

        if (routePlanInfo && (routePlanInfo.activeOrFinishedRoute || routePlanInfo.activeOrFinishedRoute === 'true') && this.notEmpty(waypoint.visited)) {
            if (!waypoint.passedThrough || waypoint.type === 10) {
                color = 'green';
            } else {
                color = 'red';
            }
        }
        return color;
    }

    getMarkerIconByWaypointType = (waypointType, pointData) => {
        switch (waypointType) {
            case 0:
                return 'fa-circle-thin';
            case 1:
                return 'fa-dot-circle-o';
            case 3:
                return 'fa-tint';
            case 4:
                return 'fa-product-hunt';
            case 7:
                return 'fa-refresh';
            case 8:
            case 10:
                return 'fa fa-ship';
            case 9:
            case 11:
                return 'fa fa-train';
            case 12:
                return 'fa-wrench';
            case 13:
                return pointData.categoryIcon;
            case 14:
            case 15:
                return 'fa fa-random';
            default:
                return 'fa-question-circle';
        }
    }

    setupTooltip = (marker, wp, last, dontUseQueue) => {
        if (wp.type === 0 || wp.type === 1) {
            if (!last) {
                let toolt = new L.tooltip({
                    interactive: true,
                    permanent: true,
                    className: "labelClass " + 'historyL' + wp.clientPlace.id,
                    direction: 'auto'
                });
                if (dontUseQueue)
                    toolt.setContent('<b>' + this.waypIndex + '</b>');
                else
                    toolt.setContent('<b>' + wp.queue + '</b>');
                marker.bindTooltip(toolt).openTooltip();
                marker.clientId = wp.clientPlace.id;
            }
        } else {
            if (!last) {
                let toolt = new L.tooltip({
                    interactive: true,
                    permanent: true,
                    className: '',
                    direction: 'auto'
                });

                if (dontUseQueue)
                    toolt.setContent('<b>' + this.waypIndex + '</b>');
                else
                    toolt.setContent('' + wp.queue);

                marker.bindTooltip(toolt).openTooltip();
            }
        }
    }

    notEmpty = (property) => {
        return property !== null && property !== undefined && property !== '';
    }

    initMap = () => {
        this.truck = this.store.getVehicle(this.truckId);
        const {longit, latit} = this.truck.eventsContainer.driverState.position;
        this.truckCoords = {
            lat: latit,
            lng: longit
        };


        let path = "https://api.latgrupe.lt/api1/wms-h/WMS";
        let xMapAttribution = '<a href="https://www.ptvgroup.com">PTV<\/a>, HERE';
        let background = new L.TileLayer.WMS(path, {
            maxZoom: 19,
            minZoom: 0,
            opacity: 1.0,
            noWrap: false,
            layers: 'xmap-sandbox-bg',
            format: 'image/png',
            transparent: false,
            attribution: xMapAttribution,
            zIndex: 1
        });
        // add (non-tiled) label layer. Insert at tile pane
        let labels = new L.TileLayer.WMS(path, {
            maxZoom: 19,
            minZoom: 0,
            opacity: 1.0,
            layers: 'xmap-sandbox-fg',
            format: 'image/png',
            transparent: true,
            attribution: xMapAttribution,
            pane: 'overlayPane',
            zIndex: 3
        });

        this.map = L.map('map', {
            center: this.truckCoords,
            zoom: 7,
        });
        this.map.addLayer(L.layerGroup([background, labels]));

    }


    render() {
        const {classes} = this.props;
        let vehicle = this.store.getVehicle(this.truckId);
        let valstnr;
        let speed;
        let ignitionState;
        if (vehicle != null) {
            valstnr = vehicle ? vehicle.valstnr : "";
            speed = vehicle.eventsContainer.driverState.position.speed;
            ignitionState = vehicle.eventsContainer.driverState.position.ignitionState;
        }


        return (
            <div>
                <MyAppBar>
                    <div
                        style={{'whiteSpace': 'nowrap'}}> {valstnr}
                    </div>
                    <div style={{'width': '70%'}}/>
                </MyAppBar>
                <div id="map"></div>
            </div>


        )
    }

}

export default injectIntl(TaskPreview);