import http from "./httpService";
// const systemSettingsPath = apiUrl + '/user-settings/system-settings';
// const saveSettingsPath = apiUrl + '/user-settings/ui-setting';
// const settingsPath = apiUrl + '/user-settings/ui-settings';
// const clientVehiclesReportPath = apiUrl + '/reports/client-vehicles';
// const selectManagerPath = apiUrl + '/managers/select-manager';
// const selectVehiclePath = apiUrl + '/vehicles/select-vehicle';
// const getActiveNotesPath = apiUrl + '/notes/list';
// const getDeletedNotesPath = apiUrl + '/notes/list-deleted';
// const newNotePath = apiUrl + '/notes/new';
// const deleteNotePath = apiUrl + '/notes/delete';
// const undeleteNotePath = apiUrl + '/notes/undelete';

const groupsPath = http.apiUrlSecured + '/groups/list';
const managersPath = http.apiUrlSecured + '/managers/truck-managers-list';
const vehiclesListPath = http.apiUrlSecured + '/vehicles/list';
const addressPath = http.apiUrlSecured + '/vehicles/getAddressByCoords';



export function getGroups() {
    return http.get(groupsPath);
}

export function getManagers() {
    return http.get(managersPath);
}

export function getVehicles(managerId, groupId) {
    return http.get(vehiclesListPath, {
        params: {
            managerId: managerId,
            groupId: groupId
        }
    });
}

export function getAddressByCoords(lat, lng) {
    return http.get(addressPath, {params: {lat: lat, lng: lng}});
}


