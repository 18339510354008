import DriverTableStore from "./DriverTableStore";
import MapStore from "./MapStore";
import {observable, action} from "mobx";
import {create} from 'mobx-persist'
import localForage from "localforage";

const hydrate = create({
    storage: localForage,
    jsonify: false
})

class RootStore {

    constructor() {
        this.driverTableStore = new DriverTableStore(this)
        this.mapStore = new MapStore(this)

        hydrate('driverTableStore', this.driverTableStore);
    }

    @observable language = "lt";
    @observable user;

    setLanguage(lang) {
        this.language = lang;
        console.log(lang);
    }

    @action
    setUser = (user) => {
        this.user = user.user;
        this.driverTableStore.loadVehicles();
    }

}

export default RootStore;