import styled from "styled-components";

export const TaskWrapper = styled.div`
    border-top: 1px solid #ddd;
    margin: 3px;
    
    &.is-selected {
        background-color: #FFF9C4;
    }
    
    &.vehicle-task-document-provided {
        background-color: #ddd;
    }
`;

export const Task = styled.div`
    min-height: 34px;
    padding: 5px;
    position: relative;
    
    &.is-future {
        border-left: 5px solid red;
    }
    
    &.is-future.is-next {
        border-left: 5px solid gray; 
    }
    
    &.is-current {
        background-color: rgba(0, 255, 0, 0.1);
        border-left: 5px solid red;
    }
    
    &.is-done {
      border-left: 5px solid #0066FF; 
    }
    
    &.is-collapsible {
        padding-right: 15px;
    }
`;