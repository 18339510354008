import * as React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
    formControl: {
        margin: 1,
        minWidth: 120,
    },
});

class GroupSelect extends React.Component {
    render() {
        const {groups} = this.props;
        return (
            <FormControl className={styles.formControl}>
                <InputLabel
                    htmlFor="groups">
                        <FormattedMessage id="group" />
                    </InputLabel>
                <Select value={this.props.value}
                        onChange={this.props.onChange}>

                    <MenuItem value={"all"}>Visi</MenuItem>
                    {groups.map(m => (
                            <MenuItem key={m.id} value={m.id}>{m.title}</MenuItem>
                        )
                    )}
                </Select>
            </FormControl>
        );
    };
};

export default  GroupSelect