import {observable} from "mobx";

class MapStore {

    constructor(rootStore) {
        this.rootStore = rootStore
    }

    @observable mapCenter  = {lat: '55.5962807', lng: '23.2081153'};;
    @observable selectedGroup = "all";


}

export default MapStore;