import React from 'react';
import auth from "../../services/authService";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer/index';
import Button from '@material-ui/core/Button/index';
import List from '@material-ui/core/List/index';
import Divider from '@material-ui/core/Divider/index';
import ListItem from '@material-ui/core/ListItem/index';
import ListItemIcon from '@material-ui/core/ListItemIcon/index';
import ListItemText from '@material-ui/core/ListItemText/index';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import {Link} from "react-router-dom";
import { FormattedMessage } from 'react-intl';


const styles = {
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
};

class SwipeableTemporaryDrawer extends React.Component {

    state = {
        open: false
    };

    hendleLogout = event => {
        auth.logout();
    }

    render() {
        const {classes} = this.props;
        const sideList = (
            <div className={classes.list}>
                <List>
                    <ListItem button key="Home" component={Link} to='/home'>
                        <ListItemIcon> <InboxIcon/></ListItemIcon>
                        <Link to="/home" />
                        <ListItemText primary={<FormattedMessage id="home" />}/>
                    </ListItem>
                </List>
                <Divider/>
                <List>
                    <ListItem button key="Logout" onClick={this.hendleLogout}>
                        <ListItemIcon> <InboxIcon/></ListItemIcon>
                        <ListItemText primary={<FormattedMessage id="logout" />}/>
                    </ListItem>
                </List>
            </div>
        );


        return (

            <div>
                <SwipeableDrawer
                    open={this.props.open}
                    onClose={this.props.toggleDrawer(false)}
                    onOpen={this.props.toggleDrawer(true)}
                >
                    <div
                        tabIndex={0}
                        role="button"
                        onClick={this.props.toggleDrawer(false)}
                        onKeyDown={this.props.toggleDrawer(false)}
                    >
                        {sideList}
                    </div>
                </SwipeableDrawer>
            </div>
        );
    }


}

SwipeableTemporaryDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SwipeableTemporaryDrawer);
