import React, {Fragment} from 'react';
import PropTypes from "prop-types";
import moment from "moment";

import {Value} from "../row/RowStyle";
import {getTranslation} from "../../../utils";

const getTimeInMinutes = (arrive, leave) => {
    let arriveDate = moment(arrive);
    let leaveDate = moment(leave);

    let diff = leaveDate.diff(arriveDate, 'minutes');
    let duration = `${diff} min.`;

    if(diff > 59) {
        let hours = diff / 60;
        let fraction = (hours % 1) * 60;
        let hoursNatural = parseInt(hours);
        let hoursFormat = getTranslation('hoursFormatString');

        if(hours >= 10) {
            hours = hoursNatural;
        } else {
            if(fraction >= 30) {
                hours = (hoursNatural + .5);
            } else {
                hours = hoursNatural;
            }
        }

        duration = hours + " " + hoursFormat;
    }

    return (
        <Fragment>
            <span className={"label"}>{getTranslation('durationLabel')}</span>
            <span className={"value"}>{" "+duration}</span>
        </Fragment>
    );
};

const DurationComponent = ({dateArrive, dateLeave, routePointId}) => {
    if(!dateArrive || !dateLeave || !routePointId)
        return null;

    return (
        <div className={`route-point-id-${routePointId}`}>
            <Value className={`route-point-id-${routePointId}`}>
                {getTimeInMinutes(dateArrive, dateLeave)}
            </Value>
        </div>
    );
};

DurationComponent.propTypes = {
    dateArrive: PropTypes.number,
    dateLeave: PropTypes.number,
    routePointId: PropTypes.number,
};

export default DurationComponent;