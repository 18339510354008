import styled from "styled-components";

export const Value = styled.span`
    font-size: 12px;
    margin: 0;
    padding: 0;

    &.is-bold {
      font-weight: 700;
    }
`;

export const TypeIcon = styled.i`
    padding-right: 5px; 
`;
