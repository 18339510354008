export const validTypes = [
    0, 1, 2, 3, 4, 7, 8, 9, 10, 11, 12, 14, 15
];

export const validTypeValues = {
    lt: {
        0: 'Pakrovimas',
        1: 'Iškrovimas',
        2: 'Tarpinis',
        3: 'Degalinė',
        4: 'Parkingas',
        7: 'Pasikeitimas',
        8: 'Keltas',
        9: 'Traukinys',
        10: 'Keltas',
        11: 'Traukinys',
        12: 'Servisas',
        14: 'Sienos kirtimas',
        15: 'Sienos kirtimas'
    },
    en: {
        0: 'Loading',
        1: 'Unloading',
        2: 'Intermediate point',
        3: 'Gas station',
        4: 'Parking',
        7: 'Change',
        8: 'Ferry',
        9: 'Train',
        10: 'Ferry',
        11: 'Train',
        12: 'Ferry',
        14: 'Border crossing',
        15: 'Border crossing'
    },
    de: {
        0: 'Beladen',
        1: 'Entladen',
        2: 'Zwischenpunkt',
        3: 'Tankstelle',
        4: 'Parkplatz',
        7: 'Veränderung',
        8: 'Fähre',
        9: 'Zug',
        10: 'Fähre',
        11: 'Zug',
        12: 'Service',
        14: 'Grenzübergang',
        15: 'Grenzübergang'
    },
    ru: {
        0: 'Загрузка',
        1: 'Разгрузка',
        2: 'Промежуточная точка',
        3: 'Бензоколонка',
        4: 'Парковка',
        7: 'Изменение',
        8: 'Паром',
        9: 'Поезд',
        10: 'Паром',
        11: 'Поезд',
        12: 'Service',
        14: 'Пересечение границы',
        15: 'Пересечение границы'
    },
    pl: {
        0: 'Załadunek',
        1: 'Rozładunek',
        2: 'Punkt pośredni',
        3: 'Stacja paliw',
        4: 'Parking',
        7: 'Zmiana',
        8: 'Prom',
        9: 'Pociąg',
        10: 'Prom',
        11: 'Pociąg',
        12: 'Service',
        14: 'Przekroczenie granicy',
        15: 'Przekroczenie granicy'
    }
};

export const validTypeIcons = {
    0: 'fa-circle-thin',
    1: 'fa-dot-circle-o',
    2: 'fa-question-circle',
    3: 'fa-tint',
    4: 'fa-product-hunt',
    7: 'fa-refresh',
    8: 'fa-ship',
    9: 'fa-train',
    10: 'fa-ship',
    11: 'fa-train',
    12: 'fa-wrench',
    14: 'fa-random',
    15: 'fa-random'
};