import React from 'react';
import PropTypes from 'prop-types';

import {TypeIcon, Value} from '../row/RowStyle';
import {validTypeIcons, validTypeValues} from "../../../routePointTypes";
import {getCurrentPageLanguage, nullOrUndefined} from "../../../utils";

const getMapInstance = () => {
    return global.map;
};

const getMarkerPoint = (id, targets) => {
    let targetKeys = Object.keys(targets);
    let result = null;

    for (let i = 0; i < targetKeys.length; i++) {
        let t = targets[targetKeys[i]];

        if (!t.routePointId)
            continue;

        if (t.routePointId === id)
            result = t;
    }

    return result;
};

const getMapTarget = (id) => {
    let result = null;

    let map = getMapInstance();
    let targets = map._targets;

    if (targets.length < 1)
        return result;

    return getMarkerPoint(id, targets);
};

const triggerTargetRouteEdit = (target) => {
    const {_events} = target;

    let clickEvent = _events.click;

    if (!clickEvent)
        return;

    if (clickEvent.length > 1)
        clickEvent[1].fn();

    if (clickEvent.length === 1)
        clickEvent[0].fn();
};

const onRoutePointClick = ({routePointId}) => {
    let target = getMapTarget(routePointId);

    if (target)
        triggerTargetRouteEdit(target);
};

const RoutePointTypeComponent = ({position}) => {
    let icon = validTypeIcons[position.type];
    if(nullOrUndefined(icon))
        icon = position.categoryIcon;

    let typeTitle = validTypeValues[getCurrentPageLanguage()][position.type];
        if(nullOrUndefined(typeTitle))
            typeTitle = position.categoryTitle;

    return (
        <div className={`task__route-point-type route-point-id-${position.routePointId}`}>

            <Value className={`route-point-id-${position.routePointId}`}>
                {icon ? <TypeIcon className={`fa ${icon} ${position.routePointId}`}/> : null}
                {typeTitle}
            </Value>
        </div>
    );
};

RoutePointTypeComponent.propTypes = {
    position: PropTypes.object,
};

export default RoutePointTypeComponent;