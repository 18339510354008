import React, {Component} from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import Grid from '@material-ui/core/Grid';

class UpdateTimer extends Component {

    state = {
        updateTimer: this.props.interval
    };

    componentDidMount() {
        this.timer = setInterval(() => this.setState({updateTimer: this.state.updateTimer - 1}), 1000);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.updateTimer == 0) {
            this.props.onUpdate();
            this.setState({updateTimer: this.props.interval});
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        return (
            <div style={{ 'display': 'flex' }}>
                {this.state.updateTimer}
                <RefreshIcon onClick={this.props.onUpdate}/>
            </div>
        );
    }
}

export default UpdateTimer;
