import React from 'react';
import PropTypes from 'prop-types';
import {getTranslation} from "../../../utils";


const OdometerComponent = ({odometerValue, routePointId}) => {
    return (
        <div style={{fontSize: '12px'}} className={`task__order-number route-point-id-${routePointId}`}>
            {`${getTranslation('odometerValueLabel')}: ${odometerValue.toFixed(1)}`}
        </div>
    );
};

OdometerComponent.propTypes = {
    odometerValue: PropTypes.number,
    routePointId: PropTypes.number,
};

export default OdometerComponent;