import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {getTranslation} from "../../../utils";



const BDFComponent = ({labelId, bdf1, bdf2}) => {
    if(!labelId)
        return null;

    return (
        <div style={{'color': '#3333FF'}}>
            <span style={{'fontSize': '12px'}}>
                <span className={"label"}>{getTranslation(labelId)}</span>
                <span className={"value"}>
                    <span style={{'padding': '0 5px'}}><i className="fa fa-building-o fa-rotate-90" style={{'marginRight': '5px'}}></i>{bdf1 ? bdf1 : '-'}</span>
                    <span style={{'padding': '0 5px'}}><i className="fa fa-building-o fa-rotate-90" style={{'marginRight': '5px'}}></i>{bdf2 ? bdf2 : '-'}</span>
                </span>            
            </span>
        </div>
    );
};

BDFComponent.propTypes = {
    labelId: PropTypes.string,
    routePointId: PropTypes.number,
    bdf1: PropTypes.string,
    bdf2: PropTypes.string
};

export default BDFComponent;