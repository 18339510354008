import React, {Component} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Store from '@material-ui/icons/Store';
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from '@material-ui/core/styles';
import {blue} from "@material-ui/core/colors";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

class SelectDialog extends Component {

    state = {
        selectedValue: ''
    };

    handleClose = () => {
    }

    handleListItemClick = (value) => {
        this.props.onClose(value);
    }

    render() {

        const {classes} = this.props;
        
        if (this.props.items == null) return null;

        return (
            <Dialog onClose={this.handleClose}
                    aria-labelledby="simple-dialog-title"
                    open={this.props.open}>
                <DialogTitle id="simple-dialog-title">Set backup
                    account</DialogTitle>
                <List>
                    {this.props.items.map(item => (
                        <ListItem button
                                  onClick={() => this.handleListItemClick(item.id)}
                                  key={item.id}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    <Store/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={item.name}/>
                        </ListItem>
                    ))}
                </List>
            </Dialog>
        );
    }
}

export default withStyles(styles)(SelectDialog);
