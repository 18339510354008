import React, {Component} from "react";
import './App.css';
import auth from "./services/authService";
import Login from './components/Login';
import {Redirect, Route, Switch} from "react-router-dom";
import NotFound from "./components/notFound";
import {toast, ToastContainer} from "react-toastify"
import ProtectedRoute from "./components/common/protectedRoute";
import Truck from "./components/truck";
import RoutePreview from "./components/routePrieview";
import Trucks from "./components/TruckList";
import "react-toastify/dist/ReactToastify.css";
import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
import "leaflet/dist/leaflet.css";
import {inject, observer} from "mobx-react";

import {addLocaleData, IntlProvider} from "react-intl";
import messages_en from './i18n/en.json';
import messages_lt from './i18n/lt.json';
import messages_ru from './i18n/ru.json';

import ltLocaleData from "react-intl/locale-data/lt";
import enLocaleData from "react-intl/locale-data/en";
import ruLocaleData from "react-intl/locale-data/ru";
import background from "./img/background.jpg";
import VehicleTasksComponent
    from "./components/task/VehicleTasksComponent";
import TaskPreview from "./components/taskPrieview";

addLocaleData(ltLocaleData);
addLocaleData(enLocaleData);
addLocaleData(ruLocaleData);

const messages = {
    'en': messages_en,
    'lt': messages_lt,
    'ru': messages_ru
};

@inject('rootStore')
@observer
class App extends Component {

    componentDidMount() {
        let user = auth.getCurrentUser();
        if (user != null)
            this.props.rootStore.setUser({user});
    }

    render() {
        const {user, language} = this.props.rootStore;

        const backgroundStyle = {
            backgroundImage: "url(" + background + ")",
            backgroundSize: 'cover',
        };

        return (


            <IntlProvider locale={language} messages={messages[language]}
                          defaultLocale="en">
                <React.Fragment>
                    <ToastContainer position={toast.POSITION.BOTTOM_LEFT}/>
                    <main >
                        <Switch>
                            <ProtectedRoute path="/home" component={Trucks}/>
                            <ProtectedRoute path="/truck/:id"
                                            component={Truck}/>
                            <ProtectedRoute path="/taskPreview/:id/:routeId/:routePointId"
                                            component={TaskPreview}/>
                            <ProtectedRoute path="/routePreview/:id"
                                            component={RoutePreview}/>
                            <ProtectedRoute path="/vehicleTasks/:id"
                                            component={VehicleTasksComponent}/>
                            <Route path="/login" component={Login}/>
                            <Route path="/not-found" component={NotFound}/>
                            <Redirect to="/home"/>
                        </Switch>
                    </main>
                </React.Fragment>
            </IntlProvider>
        );
    }
}

export default App;
