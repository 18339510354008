import styled from "styled-components";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Dropdown} from "primereact/components/dropdown/Dropdown";

export const DialogStyled = styled.div`
    position: absolute !important;
    height: calc(100vh - 240px) !important;
    z-index: 1000 !important;
    
    & .ui-dialog-titlebar {
        text-align: center; 
    }
    
    & .ui-dialog-titlebar .ui-dialog-title {
        display: block;
        float: none;
        font-size: 14px;
    }
    
    & .ui-dialog-titlebar.ui-widget-header {
        margin: 0 !important
    }
    
    & .ui-dialog-titlebar .ui-dialog-titlebar-close {
        position: absolute;
        right: 0;
    }
    
    & .ui-dialog-content {
        box-sizing: border-box;
        line-height: 1.5;
        overflow-y: auto;
        max-height: calc(100% - 100px);
    }
    
    & .ui-dropdown-filter.ui-inputtext {
        box-sizing: border-box;
        padding-right: 20px;
        font-size: 13px;
        width: 100%;
    }
    
    &.header-left-align .ui-dialog-titlebar {
        text-align: left;
    } 
`.withComponent(Dialog);

export const TaskTitle = styled.div`
    font-size: 12px;
    font-weight: 700;
    margin: 0 0 3px 0;
    padding: 0;
    
    &::after, &::before {
        clear: both;
        content: '';
        display: block;
    }
    
    .toggle-button {
        display: none;
    }
    
    &.is-collapsible {
        cursor: pointer;
        
        .toggle-button {
            display: block;
        }
    }
`;

export const DriverName = styled.div`
    font-size: 12px;
    font-weight: 700;
`;

export const TaskSection = styled.div`
    &::after, &::before {
        content: '';
        display: table;
        clear: both;
    }
`;

export const TaskBadge = styled.div`
    color: black;
    background-color: #ddd;
    border: 1px solid #ddd;
    border-radius: 50%;
    float: left;
    font-size: 10px;
    height: 12px;
    margin: 0 3px;
    padding: 1px 2px 3px 2px;
    text-align: center;
    width: 12px;
`;

export const StyledDropdown = styled.div`
    box-sizing: border-box;
    padding-right: 22px;
    position: relative;
    width: 100% !important;
    margin-top: 5px;
    
    & .ui-dropdown-trigger {
        height: 20px;
        position: absolute;
        right: -1px;
        top: -1px;
    }
    
    & .ui-dropdown-panel.ui-widget-content {
        left: -1px !important;
        position: absolute;
        width: 100%;
    }
    
    & .ui-dropdown-items-wrapper {
        overflow: auto;
    }
    
    & .ui-dropdown-item {
        box-sizing: border-box;
        font-size: 13px;
        padding: 0 10px;
        transition: 250ms background-color linear;
    }
    
    & .ui-dropdown-item:hover {
        background-color: #eee;
    }
    
    & .ui-dropdown-clear-icon {
        position: absolute;
        right: 24px;
        top: 1px;
    }
    
    & .ui-dropdown-label.ui-inputtext {
        font-size: 13px;
        padding-bottom: 0;
        padding-top: 0;
        line-height: 1.5;
    }
    
    & .ui-dropdown-filter-container {
        display: flex;
        margin: 3px;
    }
    
    & .ui-dropdown-filter-container input {
        flex-grow: 1;
        flex-shrink: 1;
        margin-right: 5px;
    }
    
    & .ui-dropdown-filter-container .fa-search {
        flex-grow: 0;
        flex-shrink: 0;
        padding-top: 2px;
    }
`.withComponent(Dropdown);
StyledDropdown.displayName = "StyledDropdown";