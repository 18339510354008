import React, {Component} from "react";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableTemporaryDrawer from './SwipeableTemporaryDrawer'
import AppBar from '@material-ui/core/AppBar';
import Home from '@material-ui/icons/Home';
import ArrowBack from '@material-ui/icons/ArrowBack';

import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
// import HomeIcon from '@material-ui/icons/AccessAlarm';

import {fade, makeStyles, withStyles} from '@material-ui/core/styles';
import {inject, observer} from "mobx-react";
import { Redirect } from "react-router-dom"
import {Link, withRouter} from "react-router-dom";

@inject('rootStore')
@observer
class MyAppBar extends Component {

    state = {
        sideMenuOpen: false
    };

    toggleDrawer = (open) => () => {
        this.setState({
            sideMenuOpen: open,
        });
    };

    openSideMenu = () => {
        this.setState({sideMenuOpen: true})
    };
    onClickBack = () => {
        this.props.history.goBack()
    };


    render() {
        return (
            <AppBar position="static">
                <SwipeableTemporaryDrawer open={this.state.sideMenuOpen}
                                          toggleDrawer={this.toggleDrawer}/>
                <Toolbar>
                    <IconButton color="inherit" aria-label="Menu"
                                onClick={this.openSideMenu}>
                        <MenuIcon/>
                    </IconButton>

                    <Link style={{color: 'white'}} to="/home">
                        <Home/></Link>

                    <IconButton color="inherit" aria-label="Menu"
                                onClick={this.onClickBack}>
                        <ArrowBack/>
                    </IconButton>

                    {this.props.children}

                </Toolbar>

            </AppBar>
        )
    }
}

export default withRouter(MyAppBar);