// @flow
import * as React from 'react';


export default class NotesComponent extends React.Component {
    render() {

        let noteClass = this.props.data.notesCount !==0
            ? "fa fa-sticky-note"
            : "fa fa-sticky-note-o"


        return (

                <i style={{
                    'color': '#e0df00', 'fontSize': '14px',
                    'marginTop': '1px', 'marginRight': '15px'
                }} className={noteClass}/>
        );
    };
};