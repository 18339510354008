import axios from "axios";
import logError from "./logService";
import {toast} from "react-toastify";
import {logout} from "./authService"

let hostname = window.location.hostname;
let port = window.location.port;
let protocol = window.location.protocol;
let fullPath = protocol + "//" + hostname + (port !== '' ? ':' + port : '');
let apiUrl = fullPath + "/api";
let apiUrlSecured = fullPath + "/api/secured";

axios.interceptors.response.use(null, error => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &
        error.response.status < 500;

    if (!expectedError) {
        console.log(error.response.data);
        if(error.response.data.includes('Not logged in web')) {
            logout();
            return;
        }
        logError(error);
        toast.error("An unexpected error occurrred.");

    }
    return Promise.reject(error);
});

function setJwt(jwt) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;
}

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    setJwt,
    apiUrl,
    apiUrlSecured

};
