import {Provider} from "mobx-react";
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import RootStore from "./stores/RootStore";
import Offline from "./offline";


const rootStore = new RootStore();

ReactDOM.render(
    <Provider rootStore={rootStore}>
        <BrowserRouter>
            {/*<App />*/}
            <Offline/>
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);
serviceWorker.register();