import React, { Component } from 'react';

import { getTranslation, getCurrentPageLanguage } from "../utils";

const NotLast = () => (
    <div className="sidebarKmsNotLast" style={{ 'alignSelf': 'center' }}>
        <i className="fa fa-long-arrow-right sidebarKmsNotLastIcon"></i>
    </div>
);

const Last = () => (
    <div className="sidebarKmsLast" style={{ 'alignSelf': 'center' }}>
        <span style={{ 'fontWeight': 'bold' }}>=</span>
    </div>
);

const Flag = () => (
    <div>
        <i className="fa fa-flag sidebarKmsFlag"></i>
    </div>
);

const KmsIndex = (props) => (
    <span className="sidebarKmsIndex">{props.index}</span>
);

const More = () => (
    <div className="sidebarKmsMore" style={{ 'alignSelf': 'center' }}
        title={getTranslation('drivers-table.kms.more')}>
        <i className="fa fa-ellipsis-h sidebarKmsMoreIcon"></i>
    </div>
);

export default class KmsComponent extends Component {

    constructor(props) {
        super(props);

        this.morePanel = null;
    }

    render() {
        let data = this.props.data;

        if (data) {
            return (
                <div>
                    <div className="sidebarKmsMainChild" style={{ 'display': 'flex' }}>
                        {data.last ? this.props.showMore ? <More /> : <Last /> : <NotLast />}
                        <div style={{ 'display': 'flex', 'flexDirection': 'column-reverse', 'opacity': this.props.hidden ? '0.5' : '1' }} title={getTranslation('drivers-table.kms.until')}>
                            <span className="sidebarKmsKms" style={{ 'background': data.red ? 'red repeat scroll 0 0' : '#03b912 none repeat scroll 0 0' }}>
                                {data.leftKms.toLocaleString(getCurrentPageLanguage(), { maximumFractionDigits: 0 })}
                            </span>
                            <div style={{ 'alignSelf': data.last ? 'flex-end' : 'center', 'height': '13px', 'marginTop': '-2px' }}>
                                {data.last ? <Flag /> : <KmsIndex index={data.queue} />}

                            </div>
                        </div>
                    </div>
                </div >
            );
        } else {
            return null;
        }
    }

}