import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {getTranslation, nullOrUndefined} from "../../../utils";
import {TruncatedText, Value} from "./OrderNumberStyle";

class OrderNumberComponent extends Component {
    constructor(props) {
        super(props);

        this.renderTruncatedText = this.renderTruncatedText.bind(this);
        this.renderLabel = this.renderLabel.bind(this);
    }

    renderTruncatedText() {
        let {routePointId, orderNumber} = this.props;

        if(nullOrUndefined(orderNumber) || orderNumber.trim().length < 1)
            return null;

        return (
            <TruncatedText className={`route-point-id-${routePointId}`}>
                {orderNumber}
            </TruncatedText>
        );
    }

    renderLabel(style) {
        let {routePointId} = this.props;

        return (
            <Value style={style || null} className={`route-point-id-${routePointId}`}>
                {getTranslation('orderNumberTitle') + ' '}
            </Value>
        );
    }

    render() {
        let {routePointId} = this.props;

        return (
            <div style={{display: 'flex'}}
                 className={`task__order-number route-point-id-${routePointId}`}>
                {this.renderLabel()}
                {this.renderTruncatedText()}
            </div>
        );
    };
}

OrderNumberComponent.propTypes = {
    belongsToNextRoute: PropTypes.bool,
    clientMode: PropTypes.bool,
    orderNumber: PropTypes.string,
    routePointId: PropTypes.number,
};

export default OrderNumberComponent;