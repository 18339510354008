import React, {Component} from 'react';

import {Task, TaskWrapper} from './TaskStyle';
import AddressComponent from "../address/AddressComponent";
import RouteLeftComponent from "../route-left/RouteLeftComponent";
import OrderNumberComponent from "../order-number/OrderNumberComponent";
import RoutePointTypeComponent
    from "../route-point-type/RoutePointTypeComponent";
import PlannedDateComponent from "../planned-date/PlannedDateComponent";

import CommentComponent from "../comment/CommentComponent";
import DateComponent from "../date/DateComponent";
import BDFComponent from "../bdf/BDFComponent";
import DurationComponent from "../duration/DurationComponent";
import RefNumberComponent from "../reference-number/RefNumberComponent";
import {getTranslation, nullOrUndefined} from "../../../utils";
import OdometerComponent from "../odometer/OdometerComponent";
import ActionComponent from "../actions-list/ActionComponent";
import {validTypeIcons} from "../../../routePointTypes";

class TaskComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
        };

        this.onClick.bind(this);

        this.renderPlanDate.bind(this);
        this.renderEnteredDate.bind(this);
        this.renderLeaveDate.bind(this);
        this.renderStandingDuration.bind(this);

        this.renderTaskDates.bind(this);
        this.renderRouteLeftLine.bind(this);
        this.renderLessImportandTask.bind(this);
        this.renderFullTask.bind(this);
        this.renderMessageButton.bind(this);
        this.renderEditButton.bind(this);
        this.renderDocumentButton.bind(this);
        this.renderToggleButton.bind(this);
        this.renderRoutePointType.bind(this);
        this.renderRefNumber.bind(this);
        this.renderOdometerValue.bind(this);

        this.renderDriverComment.bind(this);
        this.renderManagerComment.bind(this);
    }


    onClick() {
        let {position, detailedRoute, clientMode, clientSettings} = this.props;
        let routePointId = this.props.position.routePointId;

        this.props.history.push(
            "/taskPreview/" + this.props.truckId
            + "/" + this.props.selectedRouteId
            + "/" + this.props.position.routePointId);

    };

    renderEnteredDate() {
        if (this.props.clientMode && !this.props.clientSettings.showRoutePointArriveDate)
            return null;

        if (this.props.vehicleTaskType === 2)
            return null;

        let {entered, routePointId} = this.props.position;
        if (nullOrUndefined(entered))
            return null;

        return (
            <DateComponent date={entered}
                           labelid={'arriveTime'}
                           routePointId={routePointId}/>
        );
    }

    renderEnteredBDF() {
        if (!this.props.bdf.render)
            return null;

        let {arriveBdf1, arriveBdf2} = this.props.routeAccounting;

        return (
            <BDFComponent labelId={'arriveBdf'}
                          bdf1={arriveBdf1}
                          bdf2={arriveBdf2}
                          routePointId={this.props.position.routePointId}/>
        );
    }

    renderLeaveDate() {
        if (this.props.clientMode && !this.props.clientSettings.showRoutePointLeaveDate)
            return null;

        if (this.props.vehicleTaskType === 2)
            return null;

        let {visited, routePointId, passedTrough} = this.props.position;
        if (nullOrUndefined(visited))
            return null;

        return (
            <div><DateComponent date={visited}
                                labelid={'leaveTime'}
                                routePointId={routePointId}
                                passedTrough={passedTrough}/></div>
        );
    }

    renderLeaveBDF() {
        if (!this.props.bdf.render)
            return null;

        let {departBdf1, departBdf2} = this.props.routeAccounting;

        return (
            <BDFComponent labelId={'departBdf'}
                          bdf1={departBdf1}
                          bdf2={departBdf2}
                          routePointId={this.props.position.routePointId}/>
        );
    }


    renderStandingDuration() {
        if (this.props.clientMode && !this.props.clientSettings.showRoutePointStandingDuration)
            return null;

        if (this.props.vehicleTaskType === 2)
            return null;

        let {visited, entered} = this.props.position;
        if (nullOrUndefined(entered))
            return null;

        return (
            <DurationComponent routePointId={this.props.position.routePointId}
                               dateArrive={entered}
                               dateLeave={!nullOrUndefined(visited) ? visited : Date.now()}/>
        );
    }

    renderTaskDates() {
        return (
            <div
                className={`route-point-id-${this.props.position.routePointId}`}>
                {this.renderPlanDate()}
                {this.renderEnteredDate()}
                {this.props.routeAccounting && this.renderEnteredBDF()}
                {this.renderLeaveDate()}
                {this.props.routeAccounting && this.renderLeaveBDF()}
                {this.renderStandingDuration()}
            </div>
        );
    }

    renderActionsLog() {
        if (this.props.vehicleTaskType != 2
            && this.props.listActions != null
            && this.props.listActions.length > 0
            && (this.props.position.type == 0
                || this.props.position.type == 1)) {
            return (
                <div style={{display: 'inline-flex'}}>
                    <ActionComponent
                        action='Waiting'
                        actionType={this.props.waitingActionType}
                        dateLine={this.props.waitingLine}
                        duration={this.props.waitingDuration}
                        color='red'
                        type={this.props.position.type}
                        comment={this.props.waitingComment}/>
                    <ActionComponent
                        action='InProgress'
                        actionType={2}
                        dateLine={this.props.inProgressLine}
                        duration={this.props.inProgressDuration}
                        color='#f5b505'
                        type={this.props.position.type}
                        comment={this.props.inProgressComment}/>
                    <ActionComponent
                        action='Done'
                        actionType={3}
                        dateLine={this.props.doneLine}
                        color='#0a8c19'
                        type={this.props.position.type}
                        comment={this.props.doneComment}/>
                </div>
            );
        } else {
            return null;
        }
    }

    renderPlanDate() {
        if (this.props.clientMode && !this.props.clientSettings.showRoutePointPlanDate)
            return null;

        if (nullOrUndefined(this.props.routeAccounting))
            return null;

        return (
            <PlannedDateComponent
                timezone={this.props.timeZone}
                routeAccounting={this.props.routeAccounting}
                position={this.props.position}
                vehicleTaskType={this.props.vehicleTaskType}
                clientMode={this.props.clientMode}/>
        );
    }

    renderRouteLeftLine() {
        if (this.props.vehicleTaskType === 0)
            return null;

        return (
            <RouteLeftComponent icon={'fa-road'}
                                segmentKilometers={this.props.position.segmentKilometers}
                                kilometersToFinish={this.props.position.kilometersToFinish}
                                routePointId={this.props.position.routePointId}/>
        );
    }

    renderMessageButton() {
        // let {clientMode, generateRoutePointMessage, position, editTaskDialog} = this.props;
        //
        // if (clientMode || Object.keys(editTaskDialog).length > 0)
        //     return null;
        //
        // let styleObject = {position: 'absolute', right: '3px', bottom: '8px', cursor: 'pointer'};
        //
        // return (
        //     <i onClick={() => generateRoutePointMessage(position.routePointId)}
        //        className={'fa fa-envelope'}
        //        style={styleObject}/>
        // )
    }

    renderEditButton() {
        // let {clientMode, editTask, position, routeAccounting, vehicleTaskType, belongsToNextRoute,
        //     driverComment, managerComment, orderNumber, editTaskDialog, timeZone, refNumber
        // } = this.props;
        //
        // if (clientMode || Object.keys(editTaskDialog).length > 0)
        //     return null;
        //
        // let styleObject = {position: 'absolute', right: '20px', bottom: '7px', cursor: 'pointer'};
        //
        // return (
        //     <i onClick={() => editTask({
        //         timeZone,
        //         position,
        //         routeAccounting,
        //         vehicleTaskType,
        //         belongsToNextRoute,
        //         driverComment,
        //         managerComment,
        //         refNumber,
        //         orderNumber,
        //     })}
        //        className={'fa fa-edit'}
        //        style={styleObject}/>
        // )
    }

    renderDocumentButton() {
        if (this.props.clientMode === true) {
            if (this.props.clientSettings.showConversationDocuments === false)
                return null;
        }
        let styleObject = {
            position: 'absolute',
            right: this.props.clientMode ? '3px' : '40px',
            bottom: '8px',
        };
        const documentsProvided = this.props.documentsProvided || false;
        let onClickHandler = () => {
        };

        if (documentsProvided) {
            styleObject.color = "#03b912";
            styleObject.cursor = 'pointer';

            onClickHandler = () => this.props.openDocumentsDialog(this.props.position.id)
        } else {
            styleObject.color = "red";
        }

        return (
            <i onClick={onClickHandler}
               title={getTranslation('vehicleTasksUnloadDocumentsTitle')}
               className={'fa fa-file'}
               style={styleObject}/>
        )
    }

    renderToggleButton() {
        let {expanded} = this.state;
        let icon = expanded ? 'fa fa-angle-up' : 'fa fa-angle-down';

        return (
            <i onClick={() => this.setState({expanded: !this.state.expanded})}
               style={{
                   position: 'absolute',
                   right: '5px',
                   top: '5px',
                   fontSize: '14px',
                   cursor: 'pointer'
               }}
               className={icon}/>
        );
    }

    renderLessImportandTask() {

        if (this.state.expanded) {
            return this.renderFullTask(true);
        } else {
            return (
                <TaskWrapper
                    className={`task__wrapper route-point-id-${this.props.position.routePointId} ${this.props.isSelected ? 'is-selected' : ''}`}>
                    <Task
                        className={`is-collapsible task route-point-id-${this.props.position.routePointId} ${this.resolveClass(this.props.vehicleTaskType, this.props.belongsToNextRoute)}`}>

                        <AddressComponent onClick={() => this.onClick()}
                                          position={this.props.position}
                                          icon={validTypeIcons[this.props.position.type] ? validTypeIcons[this.props.position.type] : this.props.position.categoryIcon}/>

                        {this.renderRouteLeftLine()}
                        {this.renderToggleButton()}
                        {this.renderMessageButton()}
                    </Task>
                </TaskWrapper>
            );
        }
    }

    renderRoutePointType() {
        return (
            <RoutePointTypeComponent position={this.props.position}/>
        );
    }

    renderRefNumber() {
        let {refNumber, position} = this.props;

        if (nullOrUndefined(refNumber))
            return null;

        let {routePointId} = position;

        return (
            <RefNumberComponent refNumber={refNumber}
                                routePointId={routePointId}/>
        );
    }

    renderOdometerValue() {
        if (this.props.clientMode && !this.props.clientSettings.showRoutePointOdometerValue)
            return null;

        const {vehicleTaskType, position} = this.props;

        if (vehicleTaskType !== 0 || (position.type !== 0 && position.type !== 1))
            return null;

        return (
            <OdometerComponent routePointId={position.routePointId}
                               odometerValue={position.odometerValue}/>
        )
    }

    renderDriverComment() {
        if (this.props.clientMode && !this.props.clientSettings.showRoutePointDriverComment)
            return null;

        return (
            <CommentComponent comment={this.props.driverComment}
                              routePointId={this.props.position.routePointId}
                              clientMode={this.props.clientMode}
                              icon={'fa-comment-o'}
                              titleId={'driverCommentTitle'}/>
        );
    }

    renderManagerComment() {
        if (this.props.clientMode && !this.props.clientSettings.showRoutePointManagerComment)
            return null;

        return (
            <CommentComponent comment={this.props.managerComment}
                              routePointId={this.props.position.routePointId}
                              clientMode={this.props.clientMode}
                              icon={'fa-comment'}
                              titleId={'managerCommentTitle'}/>
        );
    }

    resolveClass(vehicleTaskType, belongsToNextRoute) {
        const done = vehicleTaskType === 0;
        const current = vehicleTaskType === 1;
        const future = vehicleTaskType === 2;

        return future && belongsToNextRoute ? 'is-next is-future' : future ? 'is-future' : current ? 'is-current' : done ? 'is-done' : '';
    };

    renderFullTask(collapsible = false) {
        let documentSelected = false;
        if (this.props.position.type === 1) {
            documentSelected = this.props.position.routePointId === this.props.documentDialogId
        }
        return (
            <TaskWrapper
                className={`task__wrapper route-point-id-${this.props.position.routePointId} ${this.props.isSelected ? 'is-selected' : ''} ${documentSelected ? 'vehicle-task-document-provided' : ''}`}>
                <Task
                    className={`route-point-id-${this.props.position.routePointId} ${this.resolveClass(this.props.vehicleTaskType, this.props.belongsToNextRoute)} ${collapsible ? 'is-collapsible' : ''}`}>

                    <AddressComponent onClick={() => this.onClick()}
                                      position={this.props.position}/>

                    {this.renderTaskDates()}
                    {this.renderActionsLog()}

                    {this.renderOdometerValue()}

                    <OrderNumberComponent
                        belongsToNextRoute={this.props.belongsToNextRoute}
                        clientMode={this.props.clientMode}
                        orderNumber={this.props.orderNumber}
                        routePointId={this.props.position.routePointId}/>

                    {this.renderDriverComment()}
                    {this.renderManagerComment()}

                    {this.renderRouteLeftLine()}
                    {this.renderRefNumber()}
                    {this.renderRoutePointType()}

                    {collapsible ? this.renderToggleButton() : null}

                    {this.props.position.type === 1 ? this.renderDocumentButton() : null}
                    {this.renderEditButton()}
                    {this.renderMessageButton()}
                </Task>
            </TaskWrapper>
        );
    }

    render() {
        let {type} = this.props.position;
        if (type !== 0 && type !== 1)
            return this.renderLessImportandTask();

        return this.renderFullTask(false);
    }
}

export default TaskComponent;