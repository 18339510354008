import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {Value} from '../row/RowStyle';
import {getTranslation} from "../../../utils";

const getRoutePointDate = (labelid, date) => {
    try {
        let value = moment(date).format('YYYY-MM-DD HH:mm');
        return (
            <Fragment>
                <span className={"label"}>{getTranslation(labelid)}</span>
                <span className={"value"}> {value}</span>
            </Fragment>
        );
    } catch (e) {
        console.log(e);
    }

    return null;
};

const renderPassedTrough = (passedTrough) =>{
    if(passedTrough)
        return <i title={getTranslation('vehicle-tasks.passed-trough')} className="fa fa-exclamation-triangle" style={{color: 'orange', marginLeft: '5px'}}></i>;

    return null;
}

const DateComponent = ({date, routePointId, labelid, passedTrough}) => {
    if(!date || !routePointId || !labelid)
        return null;

    return (
        <div className={`task__date route-point-id-${routePointId}`}>
            <Value className={`route-point-id-${routePointId}`}>
                {getRoutePointDate(labelid, date)}
                {renderPassedTrough(passedTrough)}
            </Value>
        </div>
    );
};

DateComponent.propTypes = {
    date: PropTypes.number,
    routePointId: PropTypes.number,
    labelid: PropTypes.string
};

export default DateComponent;