import Button from "@material-ui/core/Button";
import Paper from '@material-ui/core/Paper';
// import {getGroups} from "../services/groupsService"
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {inject, observer} from "mobx-react";
import Moment from 'react-moment';
import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import MyAppBar from "./common/myAppBar";
import {getAddressByCoords} from "../services/driversTableService";

const styles = theme => ({
        root: {
            width: '100%',

        },
        table: {
            margin: '0'

        },
        tableRow: {
            height: '10',
        },
        paper: {
            marginTop: theme.spacing(3),
            width: '100%',
            overflowX: 'auto',
            marginBottom: theme.spacing(2),
        },
    }
);

@inject('rootStore')
@observer
class Truck extends Component {

    store = this.props.rootStore.driverTableStore;
    truckId = this.props.match.params.id;

    state = {
        address: ''
    };


    componentDidMount() {
        if (this.store.allTrucks.length === 0) {
            this.store.loadVehicles().then(
                () => {
                    this.loadAddress();
                }
            );
        } else {
            this.loadAddress();
        }
    }

    loadAddress() {
        let pos = this.store.getVehicle(this.truckId).eventsContainer.driverState.position;
        getAddressByCoords(pos.latit, pos.longit).then(
            (resp) => this.setState({address: resp.data})
        );
    }

    handleMapClick = id => {
        this.props.history.push("/routePreview/" + id);
    }

    render() {
        if (this.store.allTrucks.length === 0) return null;

        const {classes} = this.props;
        const {valstnr, eventsContainer, tlf, priekaba} = this.store.getVehicle(this.truckId);
        const {position} = eventsContainer.driverState;

        const rowStyle = {height: "10px", padding: "5px"};

        return (
            <div className={classes.root}>
                <MyAppBar/>
                <Paper className={classes.paper}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow style={rowStyle}>
                                <TableCell style={rowStyle}
                                           colSpan={2} align={"center"}>
                                    <h2>
                                        <FormattedMessage
                                            id="applicationAddDeviceVehicle"/>: {valstnr}
                                        <br/>

                                        {priekaba ? <FormattedMessage
                                            id="applicationAddDeviceTrailer"/> : ""
                                        }
                                        {priekaba ? ": " + priekaba.valstnr: ""}
                                        <br/>

                                        <FormattedMessage
                                            id="updated"/>: <Moment format="YYYY.MM.DD HH:mm">{position.datTim}</Moment>
                                    </h2>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            <TableRow style={rowStyle}>
                                <TableCell style={rowStyle}
                                           align={"right"}>
                                    <FormattedMessage
                                        id="applicationAddDeviceAddress"/>
                                </TableCell>
                                <TableCell style={rowStyle}
                                           align={"left"}>
                                    {this.state.address}
                                </TableCell>
                            </TableRow>
                            <TableRow style={rowStyle}>
                                <TableCell style={rowStyle}
                                           align={"right"}>
                                    <FormattedMessage
                                        id="indexRoutingControlCoords"/>
                                </TableCell>
                                <TableCell style={rowStyle}
                                           align={"left"}>
                                    {position.latit}
                                    <br/>
                                    {position.longit}
                                </TableCell>
                            </TableRow>

                            <TableRow style={rowStyle}>
                                <TableCell style={rowStyle} align={"right"}>
                                    <FormattedMessage
                                        id="applicationAddDeviceSpeed"/>
                                </TableCell>
                                <TableCell style={rowStyle}
                                           align={"left"}> {position.speed}
                                </TableCell>
                            </TableRow>

                            <TableRow style={rowStyle}>
                                <TableCell style={rowStyle} align={"right"}>
                                    RPM
                                </TableCell>
                                <TableCell style={rowStyle}
                                           align={"left"}> {position.engineRpm}
                                </TableCell>
                            </TableRow>

                            <TableRow style={rowStyle}>
                                <TableCell style={rowStyle}
                                           align={"right"}>
                                    <FormattedMessage
                                        id="applicationAddDeviceOdometer"/>
                                </TableCell>
                                <TableCell style={rowStyle}
                                           align={"left"}>
                                    {position.rawOdometer}
                                </TableCell>
                            </TableRow>
                            <TableRow style={rowStyle}>
                                <TableCell style={rowStyle}
                                           align={"right"}>
                                    <FormattedMessage
                                        id="fuelLevel"/>
                                </TableCell>
                                <TableCell style={rowStyle}
                                           align={"left"}>
                                    {position.fuelLevel} l
                                </TableCell>
                            </TableRow>
                            <TableRow style={rowStyle}>
                                <TableCell style={rowStyle}
                                           align={"right"}>
                                    <FormattedMessage
                                        id="applicationAddDevicePhone"/>
                                </TableCell>
                                <TableCell style={rowStyle}
                                           align={"left"}>
                                    {tlf}
                                </TableCell>
                            </TableRow>
                            <TableRow style={rowStyle}>
                                <TableCell style={rowStyle}
                                           align={"right"}>
                                    <FormattedMessage
                                        id="applicationAddDeviceEngineStatus"/>
                                </TableCell>
                                <TableCell style={rowStyle}
                                           align={"left"}>
                                    {position.ignitionState}
                                </TableCell>
                            </TableRow>
                            <TableRow style={rowStyle}>
                                <TableCell style={rowStyle}
                                           align={"right"}>
                                    <FormattedMessage
                                        id="application.battery.voltage"/>:
                                </TableCell>
                                <TableCell style={rowStyle}
                                           align={"left"}>
                                    {position.voltage / 1000} V
                                </TableCell>
                            </TableRow>
                            <TableRow style={rowStyle}>
                                <TableCell style={rowStyle}
                                           align={"right"}>
                                    1 - <FormattedMessage id="applicationAxis"/>:
                                </TableCell>
                                <TableCell style={rowStyle}
                                           align={"left"}>
                                    {position.axis1}
                                </TableCell>
                            </TableRow>
                            <TableRow style={rowStyle}>
                                <TableCell style={rowStyle}
                                           align={"right"}>
                                    2 - <FormattedMessage id="applicationAxis"/>:
                                </TableCell>
                                <TableCell style={rowStyle}
                                           align={"left"}>
                                    {position.axis2}
                                </TableCell>
                            </TableRow>
                            <TableRow style={rowStyle}>
                                <TableCell style={rowStyle}
                                           align={"right"}>
                                    3 - <FormattedMessage id="applicationAxis"/>:
                                </TableCell>
                                <TableCell style={rowStyle}
                                           align={"left"}>
                                    {position.axis3}
                                </TableCell>
                            </TableRow>

                            <TableRow style={rowStyle}>
                                <TableCell style={rowStyle}
                                           colSpan={2} align={"center"}>
                                    <Button variant="contained" color="primary"
                                            className={classes.button}
                                            onClick={() => this.handleMapClick(this.truckId)}>
                                        <FormattedMessage id="map"/>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        )
    }

}

export default withStyles(styles)(Truck);