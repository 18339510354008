import React, {Component} from 'react';
import {getTranslation} from "../utils";
import 'font-awesome/css/font-awesome.min.css';

const TruckIcon = (props) => (
    <div style={{
        'flexBasis': '22px',
        'textAlign': 'center',
        'alignSelf': 'center'
    }}
         onClick={props.onClickEvent}>
        <i className="fa fa-truck fa-flip-horizontal driverStateAlign"/>
    </div>
);

const TabletIcon = (props) => (
    <div style={{
        'flexBasis': '22px',
        'textAlign': 'center',
        'alignSelf': 'center'
    }}
         onClick={props.onClickEvent}>
        <span className="fa-stack">
            <i className="fa fa-tablet fa-stack-1x" style={{'fontSize': '20px'}}/>
            <i className="fa fa-wifi fa-stack-1x" style={{'fontSize': '8px'}}/>
        </span>
    </div>
);

export default class DriverStateComponent extends Component {

    stateTemplate(stateTitle, leftIcon, rightIcon) {
        return (
            <div style={{'display': 'flex', 'marginRight': '15px'}}
                 title={stateTitle}>
                {leftIcon}
                {rightIcon}
            </div>
        );
    }

    leftIconTemplate(content, customStyle) {
        let style = {
            'flexBasis': '26px',
            'flexGrow': '0',
            'flexShrink': '0',
            'textAlign': 'center'
        };
        style = Object.assign({}, style, customStyle);
        return (
            <div style={style}>
                {content}
            </div>
        );
    }

    renderDrivingState(rowData, onClickEvent) {
        let content = (
            <span style={{'fontSize': '10px', 'color': '#03b912'}}>
                {rowData.eventsContainer.driverState.position ? rowData.eventsContainer.driverState.position.speed : 'N/A'}
            </span>
        );
        return this.stateTemplate(getTranslation('drivers-table.driver-state.driving'), this.leftIconTemplate(content),
            <TruckIcon onClickEvent={onClickEvent}/>);
    }

    renderDrivingTabletState(rowData, onClickEvent) {
        let content = (
            <span style={{'fontSize': '10px', 'color': '#03b912'}}>
                {rowData.eventsContainer.driverState.position ? rowData.eventsContainer.driverState.position.speed : 'N/A'}
            </span>
        );
        return this.stateTemplate(getTranslation('drivers-table.driver-state.data-from-tablet'), this.leftIconTemplate(content, {'marginTop': '3px'}),
            <TabletIcon onClickEvent={onClickEvent}/>);
    }

    renderWaitingState(rowData, onClickEvent) {
        let content = (
            <i style={{
                'color': '#03b912',
                'fontSize': '14px',
                'marginTop': '1px'
            }} className="fa fa-circle driverStateAlign"/>
        );
        return this.stateTemplate(getTranslation('drivers-table.driver-state.waitting'), this.leftIconTemplate(content),
            <TruckIcon onClickEvent={onClickEvent}/>);
    }

    renderWaitingTabletState(rowData, onClickEvent) {
        let content = (
            <i style={{
                'color': '#1081e0',
                'fontSize': '14px',
                'marginTop': '1px'
            }} className="fa fa-circle driverStateAlign"/>
        );
        return this.stateTemplate(getTranslation('drivers-table.driver-state.waitting'), this.leftIconTemplate(content, {'marginTop': '3px'}),
            <TabletIcon onClickEvent={onClickEvent}/>);
    }

    renderOffState(rowData, onClickEvent) {
        let content = (
            <i style={{
                'color': 'grey',
                'fontSize': '14px',
                'marginTop': '1px'
            }} className="fa fa-circle driverStateAlign"/>
        );
        return this.stateTemplate(getTranslation('drivers-table.driver-state.off'), this.leftIconTemplate(content),
            <TruckIcon onClickEvent={onClickEvent}/>);
    }

    renderLongOffState(rowData, onClickEvent) {
        let content = (
            <i style={{'fontSize': '14px', 'marginTop': '1px'}}
               className="fa fa-circle-thin driverStateAlign"/>
        );
        return this.stateTemplate(getTranslation('drivers-table.driver-state.long-off'), this.leftIconTemplate(content),
            <TruckIcon onClickEvent={onClickEvent}/>);
    }

    renderGPSLostState(rowData, onClickEvent) {
        let content = <i
            style={{'color': 'red', 'fontSize': '14px', 'marginTop': '1px'}}
            className="fa fa-dot-circle-o driverStateAlign"/>
        return this.stateTemplate(getTranslation('drivers-table.driver-state.gps-lost'), this.leftIconTemplate(content),
            <TruckIcon onClickEvent={onClickEvent}/>);
    }

    renderRefuelingState(rowData, onClickEvent) {
        let content = (
            <i style={{
                'color': '#03b912',
                'fontSize': '14px',
                'marginTop': '1px'
            }} className="fa fa-tint driverStateAlign"/>
        );
        return this.stateTemplate(getTranslation('drivers-table.driver-state.refueling'), this.leftIconTemplate(content),
            <TruckIcon onClickEvent={onClickEvent}/>);
    }

    renderParkingState(rowData, onClickEvent) {
        let content = (
            <i style={{
                'color': '#03b912',
                'fontSize': '14px',
                'marginTop': '1px'
            }} className="fa fa-product-hunt driverStateAlign"/>
        );
        return this.stateTemplate(getTranslation('drivers-table.driver-state.parking'), this.leftIconTemplate(content),
            <TruckIcon onClickEvent={onClickEvent}/>);
    }

    renderLoadingState(rowData, onClickEvent) {
        let loadingDuration = (
            <div style={{'fontSize': '10px'}}>
                <span>{rowData.eventsContainer.hoursInRoutePointRadius.formattedValue}</span>
            </div>
        );
        let content = (
            <React.Fragment>
                <div style={{
                    'fontSize': '0.9em',
                    'position': 'relative',
                    'animation': '1.5s linear 2s alternate none running backFoward',
                    'animationIterationCount': '5'
                }}>
                    <i className="fa fa-arrow-right"
                       style={{
                           'fontSize': '0.9em',
                           'color': rowData.eventsContainer.driverState.actionDate !== null ? rowData.eventsContainer.driverState.action ? '#03b912' : 'red' : 'grey'
                       }}/>
                </div>
                {rowData.eventsContainer.hoursInRoutePointRadius.realValue !== -1 && loadingDuration}
            </React.Fragment>
        );
        return this.stateTemplate(getTranslation('drivers-table.driver-state.loading'), this.leftIconTemplate(content, {
            'flexBasis': '24px',
            'padding': '0 1px'
        }), <TruckIcon onClickEvent={onClickEvent}/>);
    }

    renderUnloadingState(rowData, onClickEvent) {
        let loadingDuration = (
            <div style={{'fontSize': '10px'}}>
                <span>{rowData.eventsContainer.hoursInRoutePointRadius.formattedValue}</span>
            </div>
        );
        let content = (
            <div>
                <div style={{
                    'fontSize': '0.9em',
                    'position': 'relative',
                    'animation': '1.5s linear 2s alternate none running backFoward',
                    'animationIterationCount': '5'
                }}>
                    <i className="fa fa-arrow-left"
                       style={{
                           'fontSize': '0.9em',
                           'color': rowData.eventsContainer.driverState.actionDate !== null ? rowData.eventsContainer.driverState.action ? '#03b912' : 'red' : 'grey'
                       }}/>
                </div>
                {rowData.eventsContainer.hoursInRoutePointRadius.realValue !== -1 && loadingDuration}
            </div>
        );
        return this.stateTemplate(getTranslation('drivers-table.driver-state.unloading'), this.leftIconTemplate(content, {
            'flexBasis': '24px',
            'padding': '0 1px'
        }), <TruckIcon onClickEvent={onClickEvent}/>);
    }

    renderAutoServiceState(rowData, onClickEvent) {
        let content = (
            <i style={{
                'color': '#03b912',
                'fontSize': '13px',
                'marginTop': '1px'
            }} className="fa fa-wrench driverStateAlign"/>
        );
        return this.stateTemplate(getTranslation('drivers-table.driver-state.auto-service'), this.leftIconTemplate(content),
            <TruckIcon onClickEvent={onClickEvent}/>);
    }

    renderCommonPlaceState(rowData, onClickEvent) {
        let content = (
            <i style={{
                'color': '#03b912',
                'fontSize': '14px',
                'marginTop': '1px'
            }}
               className={rowData.eventsContainer.driverState.icon + ' driverStateAlign'}/>
        );
        return this.stateTemplate(getTranslation('drivers-table.driver-state.common-place'), this.leftIconTemplate(content),
            <TruckIcon onClickEvent={onClickEvent}/>);
    }

    renderBorderCrossingState(rowData, onClickEvent) {
        let content = (
            <i style={{
                'color': '#03b912',
                'fontSize': '13px',
                'marginTop': '2px'
            }} className="fa fa-random driverStateAlign"/>
        );
        return this.stateTemplate(getTranslation('drivers-table.driver-state.border-crossing'), this.leftIconTemplate(content),
            <TruckIcon onClickEvent={onClickEvent}/>);
    }

    renderFerryState(rowData, onClickEvent) {
        let content = (
            <i style={{'fontSize': '11px', 'marginTop': '3px'}}
               className="fa fa-ship driverStateAlign"/>
        );
        return this.stateTemplate(getTranslation('drivers-table.driver-state.ferry'), this.leftIconTemplate(content),
            <TruckIcon onClickEvent={onClickEvent}/>);
    }

    renderTrainState(rowData, onClickEvent) {
        let content = (
            <i style={{'fontSize': '14px', 'marginTop': '1px'}}
               className="fa fa-train driverStateAlign"/>
        );
        return this.stateTemplate(getTranslation('drivers-table.driver-state.train'), this.leftIconTemplate(content),
            <TruckIcon onClickEvent={onClickEvent}/>);
    }

    renderTarpinisState(rowData, onClickEvent) {
        let content = (
            <span className="fa-stack" style={{'width': '15px'}}>
                <i className="fa fa-circle-thin fa-stack-1x"
                   style={{'fontSize': '15px'}}/>
                <i className="fa fa-question fa-stack-1x"
                   style={{'fontSize': '8px', 'color': 'blue'}}/>
            </span>
        );
        return this.stateTemplate(getTranslation('drivers-table.driver-state.intermediate'), this.leftIconTemplate(content),
            <TruckIcon onClickEvent={onClickEvent}/>);
    }

    render() {
        let rowData = this.props.data;
        if (rowData.eventsContainer) {
            return (
                <div className="driver-state"
                     style={{'width': '48px', 'alignSelf': 'center'}}>
                    {rowData.eventsContainer.driverState.state == 'DRIVING' && this.renderDrivingState(rowData, this.props.onClickEvent)}
                    {rowData.eventsContainer.driverState.state == 'DRIVING_TABLET' && this.renderDrivingTabletState(rowData, this.props.onClickEvent)}
                    {rowData.eventsContainer.driverState.state == 'WAITING' && this.renderWaitingState(rowData, this.props.onClickEvent)}
                    {rowData.eventsContainer.driverState.state == 'WAITING_TABLET' && this.renderWaitingTabletState(rowData, this.props.onClickEvent)}
                    {rowData.eventsContainer.driverState.state == 'OFF' && this.renderOffState(rowData, this.props.onClickEvent)}
                    {rowData.eventsContainer.driverState.state == 'LONG_OFF' && this.renderLongOffState(rowData, this.props.onClickEvent)}
                    {rowData.eventsContainer.driverState.state == 'GPS_LOST' && this.renderGPSLostState(rowData, this.props.onClickEvent)}
                    {rowData.eventsContainer.driverState.state == 'REFUELING' && this.renderRefuelingState(rowData, this.props.onClickEvent)}
                    {rowData.eventsContainer.driverState.state == 'PARKING' && this.renderParkingState(rowData, this.props.onClickEvent)}
                    {rowData.eventsContainer.driverState.state == 'LOADING' && this.renderLoadingState(rowData, this.props.onClickEvent)}
                    {rowData.eventsContainer.driverState.state == 'UNLOADING' && this.renderUnloadingState(rowData, this.props.onClickEvent)}
                    {rowData.eventsContainer.driverState.state == 'AUTO_SERVICE' && this.renderAutoServiceState(rowData, this.props.onClickEvent)}
                    {rowData.eventsContainer.driverState.state == 'COMMON_PLACE' && this.renderCommonPlaceState(rowData, this.props.onClickEvent)}
                    {rowData.eventsContainer.driverState.state == 'BORDER_CROSSING' && this.renderBorderCrossingState(rowData, this.props.onClickEvent)}
                    {rowData.eventsContainer.driverState.state == 'FERRY' && this.renderFerryState(rowData, this.props.onClickEvent)}
                    {rowData.eventsContainer.driverState.state == 'TRAIN' && this.renderTrainState(rowData, this.props.onClickEvent)}
                    {rowData.eventsContainer.driverState.state == 'TARPINIS' && this.renderTarpinisState(rowData, this.props.onClickEvent)}
                </div>
            );
        } else {
            return "";
        }
    }
}

