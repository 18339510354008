import {
    Button,
    Checkbox,
    CssBaseline,
    FormControl,
    FormControlLabel,
    Input,
    InputLabel,
    Paper
} from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React, {Component} from "react";
import {FormattedMessage} from 'react-intl';
import {Redirect} from "react-router-dom";
import logo from "../img/logo.png";
import {toast} from "react-toastify";
import background from "../img/background.jpg";
import {inject, observer} from "mobx-react";

import auth from "../services/authService";
import SelectDialog from "../components/SelectDialog"


const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
});

@inject('rootStore')
@observer
class Login extends Component {

    store = this.props.rootStore.driverTableStore;

    state = {
        username: "",
        password: "",
        dialogOpen: false,
        companyConfig: null
    };

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }
    onDialogClose = companyId => {
        this.login(companyId);
        this.setState({dialogOpen: false});

    }

    handleSubmit = async event => {

        if (this.state.username == 'system') {
            let companyConfig = await auth.getCompanyConfig();
            this.setState({
                companyConfig: companyConfig.data,
                dialogOpen: true
            });

            return;
        }
        event.preventDefault();

        this.login(null);
    }


    login = async (companyId) => {
        try {
            await auth.login(this.state.username, this.state.password, companyId);
            this.store.selectedManager = this.state.username == 'system'
                ? 0
                : auth.getCurrentUser().drbId;
            window.location.reload();
        } catch (e) {
            toast.error(<FormattedMessage id="wrong_login"/>);
        }
    }

    validateForm() {
        return this.state.username.length > 0 && this.state.password.length > 0;
    }

    render() {

        if (auth.getCurrentUser())
            return (
                <Redirect to="/home"/>
            );

        const {classes} = this.props;

        return (
            <div>
                <main className={classes.main}>
                    <CssBaseline/>
                    <Paper className={classes.paper}>

                        <img width='100%' src={logo}/>

                        <form className={classes.form}>
                            <FormControl margin="normal" required fullWidth
                                         onChange={this.handleChange}>

                                <InputLabel htmlFor="username">
                                    <FormattedMessage id="username"/>
                                </InputLabel>

                                <Input id="username" name="username"/>

                            </FormControl>
                            <FormControl margin="normal" required fullWidth
                                         onChange={this.handleChange}>
                                <InputLabel htmlFor="password">
                                    <FormattedMessage id="password"/>
                                </InputLabel>
                                <Input name="password" type="password"
                                       id="password"/>
                            </FormControl>
                            <FormControlLabel
                                control={<Checkbox value="remember"
                                                   color="primary"/>}
                                label={<FormattedMessage id="remember_me"/>}
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={!this.validateForm()}
                                className={classes.submit}
                                onClick={this.handleSubmit}
                            >
                                <FormattedMessage id="login"/>
                            </Button>
                        </form>
                    </Paper>
                </main>

                <SelectDialog open={this.state.dialogOpen}
                              items={this.state.companyConfig}
                              onClose={this.onDialogClose}/>
            </div>
        );
    }


}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);