import http from "./httpService";

const activeRoutePath = http.apiUrlSecured + '/routes/showActiveRoute';
const routePlanListPath = http.apiUrlSecured + '/routes/routePlanList';
const vehicleTasksPath = http.apiUrlSecured + '/routes/routePlan';
const getDetailedRoutePlanPath = http.apiUrlSecured + '/routes/getDetailedRoutePlan';

export function getActiveRoute(vehicleId) {
    return http.get(activeRoutePath + '/' + vehicleId);
}

export function getRoutePlanList(vehicleId) {
    return http.get(routePlanListPath + '/' + vehicleId);
}

export function getVehicleTasks(routePlanId) {
    return http.get(vehicleTasksPath + '/' + routePlanId);
}

export function getDetailedRoutePlan(routePlanId) {
    return http.get(getDetailedRoutePlanPath + '/' + routePlanId);
}
