import React from 'react';
import PropTypes from 'prop-types';

import {TypeIcon, Value} from '../row/RowStyle';
import {nullOrUndefined} from "../../../utils";

const getLeftKms = (segmentKilometers, kilometersToFinish) => {
    if (segmentKilometers === -1 && kilometersToFinish === -1)
        return null;

    let result = null;

    if (segmentKilometers === -1) {
        result = Math.round(kilometersToFinish).toFixed() + ' km';
    } else {
        result = `${Math.round(segmentKilometers).toFixed()} km / ${Math.round(kilometersToFinish).toFixed()} km`;
    }

    return result;
};

const RouteLeftComponent = ({icon, routePointId, segmentKilometers, kilometersToFinish}) => {
    if(nullOrUndefined(routePointId))
        return null;

    return (
        <div className={`task__route-left route-point-id-${routePointId}`}>
            <Value className={`route-point-id-${routePointId}`}>
                {icon ? <TypeIcon className={`fa ${icon} route-point-id-${routePointId}`}/> : null}
                {getLeftKms(segmentKilometers, kilometersToFinish)}
            </Value>
        </div>
    )
};

RouteLeftComponent.propTypes = {
    icon: PropTypes.string,
    routePointId: PropTypes.number,
    kilometersToFinish: PropTypes.number,
    segmentKilometers: PropTypes.number,
};

export default RouteLeftComponent;