import http from "./httpService";


export default function logError(error) {
    console.error(error);
    let logPath = http.apiUrl + '/log';
    var params = new URLSearchParams();
    params.append('error', error);
    http.post(logPath, params);
}
