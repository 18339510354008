import React, { Component } from 'react';

import { getTranslation, getCurrentPageLanguage } from "../utils";

import KmsComponent from "./KmsComponent";
import LeftKmsPanelComponent from "./LeftKmsPanelComponent";

const Corridor = (props) => (
    <div style={{ 'display': 'flex' }}>
        <div style={{ 'padding': '0', 'display': 'inline-block', 'margin-left': '0', 'position': 'relative', 'top': '3px', 'alignSelf': 'center' }}>
            <div className="icon-badge-wrapper">
                <i className="fa fa-road" style={{ 'font-size': '1.3em', 'color': '#ffa726' }} />
                <span className="badge-not-read" style={{ 'top': '-7px' }}>
                    {props.length.toLocaleString(getCurrentPageLanguage(), { maximumFractionDigits: 0 })}
                </span>
            </div>
        </div >
    </div>
);

const NoBDF = (props) => (
    <div style={{ 'display': 'flex' }}>
        <div  title={getTranslation('drivers-table.not-all-bdf-loaded')}
            style={{ 'padding': '0', 'display': 'inline-block', 'margin-left': '5px', 'position': 'relative', 'top': '3px', 'alignSelf': 'center' }}>
            <div className="icon-badge-wrapper">
                <i className="fa fa-building-o fa-rotate-90" style={{ 'font-size': '1em', 'color': 'red' }} />
            </div>
        </div >
    </div>
);

export default class LeftKmsComponent extends Component {

    constructor(props) {
        super(props);

        this.morePanel = null;
        this.curComponent = React.createRef();
    }

    showMoreKmsPanel(e) {
        e.stopPropagation();
        if (this.props.showAllRoutePoints) {
            this.morePanel.show();
            console.log(this.curComponent.getBoundingClientRect());
        }
    }

    render() {
        let rowData = this.props.data;
        if (rowData.eventsContainer) {
            this.morePanel = <LeftKmsPanelComponent data={rowData.eventsContainer.leftKmsList} />;
            let hiddenWayPointTypes = this.props.hiddenWayPointTypes;
            let showAllRoutePoints = this.props.showAllRoutePoints;
            if (!Array.isArray(hiddenWayPointTypes)) {
                hiddenWayPointTypes = [];
            }
            let hiddenKms = rowData.hiddenKms;
            if (!Array.isArray(hiddenKms)) {
                hiddenKms = [];
            }
            let filteredLeftKmsList = rowData.eventsContainer.leftKmsList
                .filter((leftKms) => !hiddenWayPointTypes.includes(leftKms.wayPointType) && !hiddenKms.includes(leftKms.index)
                        && (leftKms.last || leftKms.leftKms.toLocaleString(getCurrentPageLanguage(), { maximumFractionDigits: 0 }) !== "0"));
            let leftKmsList = [];
            if (filteredLeftKmsList && filteredLeftKmsList.length > 0) {
                let showMore = false;
                if (showAllRoutePoints) {
                    //Show all types with ability to hide
                    showMore = hiddenKms.length > 0 || filteredLeftKmsList.length > 2;
                } else {
                    //Show only loading/unloading
                    filteredLeftKmsList = filteredLeftKmsList.filter((leftKms) => [0, 1].includes(leftKms.wayPointType));
                }
                if (filteredLeftKmsList.length == 1) {
                    leftKmsList.push(<KmsComponent key={1} data={filteredLeftKmsList[0]} showMore={showMore} />);
                } else {
                    leftKmsList.push(<KmsComponent key={2}  data={filteredLeftKmsList[0]} showMore={showMore} />);
                    // let lastKms = filteredLeftKmsList.filter((leftKms) => leftKms.last)[0];
                    let lastKms = filteredLeftKmsList[filteredLeftKmsList.length-1];
                    if (lastKms) {
                        leftKmsList.push(<KmsComponent key={3} data={lastKms} showMore={showMore} />);
                    }
                }
            }
            // if (rowData.eventsContainer.corridor) {
            //     leftKmsList.push(<Corridor length={rowData.eventsContainer.corridor.length} />);
            // }
            if (rowData.eventsContainer.frontEndEvents && rowData.eventsContainer.frontEndEvents.length !== 0) {
                let lastCorridorEv = null;
                for (let j = 0; j < rowData.eventsContainer.frontEndEvents.length; j++) {
                    const ev = rowData.eventsContainer.frontEndEvents[j];
                    if (ev.eventType === 4) {
                        lastCorridorEv = ev;
                    }
                }
                if (lastCorridorEv) {
                    leftKmsList.push(<Corridor length={lastCorridorEv.length} />);
                }
            }

            // Add NoBDF icon if one of BDF is not set
            if (rowData.canTransportContainers) {
                if (rowData.eventsContainer && rowData.eventsContainer.leftKmsList) {
                    let nextWp = rowData.eventsContainer.leftKmsList.filter(kms => kms.wayPointType == 0 || kms.wayPointType == 1)[0];
                    if (nextWp) {
                        if (nextWp.containerNo1 && nextWp.containerNo1.length > 0
                            && nextWp.containerNo2 && nextWp.containerNo2.length > 0) {
                            //Do nothing
                        } else {
                            leftKmsList.push(<NoBDF />);
                        }
                    }
                }
            }

            //

            return (
                <div ref={(el) => this.curComponent = el} className='left-kms' style={{ 'alignSelf': 'center', 'display': 'flex', 'height': '24px' }}
                     onClick={(e) => this.showMoreKmsPanel(e)}
                >
                    {leftKmsList}
                    <LeftKmsPanelComponent ref={(el) => this.morePanel = el} data={rowData.eventsContainer.leftKmsList}
                        toggleHandler={this.props.toggleHandler} hiddenKms={hiddenKms}
                        toggleKmsPanelHandler={this.props.toggleKmsPanelHandler}
                        hiddenWayPointTypes={hiddenWayPointTypes} parent={this.curComponent}
                        idTrpr={rowData.idTrpr} visible={rowData.kmsPanelVisible} 
                        clientMode={this.props.clientMode}/>
                </div>
            );
        } else {
            return "";
        }
    }
}