import {inject, observer} from "mobx-react";
import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";

import MyAppBar from "../common/myAppBar";
import {
    getActiveRoute,
    getRoutePlanList,
    getVehicleTasks
} from "../../services/routesService";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {nullOrUndefined} from "../utils";

import {FormattedMessage} from "react-intl";
import Task from './components/task/TaskComponent';
import {
    DialogStyled,
    DriverName,
    StyledDropdown,
    TaskBadge,
    TaskTitle
} from './VehicleTasksStyle';

const styles = theme => ({
        root: {
            width: '100%',
        },
        table: {
            margin: '0'

        },
        tableRow: {
            height: '10',
        },
        paper: {
            marginTop: theme.spacing(3),
            width: '100%',
            overflowX: 'auto',
            marginBottom: theme.spacing(2),
        },
        select: {

            width: '90%',
            height: 37
        }
    }
);

@inject('rootStore')
@observer
class VehicleTasks extends Component {

    truckId = this.props.match.params.id;
    store = this.props.rootStore.driverTableStore;

    state = {
        routePlanList: null,
        selectedRouteId: null,
        vehicleTasks: null,
        profileSettings: null,
        clientSettings: null,
        futureCategoryExpanded: false
    };

    async componentDidMount() {
        if (this.store.allTrucks.length === 0) {
            await this.store.loadVehicles();
        }
        this.loadData();

    }

    loadData = async () => {

        let routePlanList = await getRoutePlanList(this.truckId)
        let selectedTruck = this.store.allTrucks.filter(truck => truck.idTrpr == this.truckId);
        if (selectedTruck.length === 0) return;
        let activeRoutePlanId = selectedTruck[0].eventsContainer.activeRoutePlanId;

        if (activeRoutePlanId == null) {
            activeRoutePlanId = routePlanList.data.data[0].id;
        }
        let vehicleTasks = await getVehicleTasks(activeRoutePlanId);
        this.setState({
            selectedRouteId: activeRoutePlanId,
            vehicleTasks: vehicleTasks.data,
            driverFullName: selectedTruck[0].driverFullName,
            routePlanList: routePlanList.data
        });

    }

    onRouteChange = async (event) => {
        let routeId = event.target.value;
        let vehicleTasks = await getVehicleTasks(routeId);
        this.setState({
            selectedRouteId: routeId,
            vehicleTasks: vehicleTasks.data
        })
    }

    renderKilometersToFinish() {
        if (this.state.vehicleTasks == null || this.state.vehicleTasks.kilometersToFinish < 0) {
            return null;

        }

        return (
            <span>
                <span className={'fa fa-flag'} style={{paddingLeft: '6px'}}/>
                <span style={
                    {
                        color: 'white',
                        display: 'inline-block',
                        fontSize: '11px',
                        fontWeight: 400,
                        padding: '0 2px',
                        backgroundColor: 'red',
                        border: '1px solid red',
                        borderRadius: '3px',
                        marginLeft: '3px',
                        verticalAlign: 'middle'
                    }}>

                    {Math.round(this.state.vehicleTasks.kilometersToFinish).toFixed()}
                </span>
            </span>
        );
    }

    renderTasks() {
        let {doneTasks, taskInProgress, futureTasks, vehicleId} = this.state.vehicleTasks;

        if (vehicleId === -1)
            return null;

        let result = (
            <div>
                {this.renderDoneTasks(doneTasks)}
                {this.renderCurrentTask(taskInProgress)}
                {this.renderFutureTasks(futureTasks)}
            </div>
        );

        return result;
    }

    renderDoneTasks(taskList) {
        if (taskList.length < 1)
            return null;

        let {doneTasks, taskInProgress, futureTasks} = this.state.vehicleTasks

        let catExpanded = false;
        let catExpandable = false;
        let filteredTasks = taskList;
        if (doneTasks != null && taskInProgress == null
            && (futureTasks == null || futureTasks.length == 0)) {
            catExpanded = true;
        } else {
            catExpandable = true;
            catExpanded = this.state.doneCategoryExpanded;
            if (!this.state.doneCategoryExpanded) {
                filteredTasks = [];
            }
        }

        return (
            <div>
                <TaskTitle className={catExpandable ? 'is-collapsible' : ''}
                           onClick={() => this.setState({doneCategoryExpanded: !this.state.doneCategoryExpanded})}>

                    <div style={{float: 'left', marginLeft:10}}>
                        <FormattedMessage id="done"/>
                    </div>

                    <TaskBadge>
                        {taskList.length}
                    </TaskBadge>

                    <div className={'toggle-button'} style={{float: 'left'}}>
                        {
                            catExpanded ?
                                <i style={{paddingLeft: '4px'}}
                                   className={'fa fa-angle-up'}/> :
                                <i style={{paddingLeft: '4px'}}
                                   className={'fa fa-angle-down'}/>
                        }
                    </div>
                </TaskTitle>

                {this.renderTaskList(filteredTasks)}

            </div>
        )
    }

    renderCurrentTask(taskInProgress) {

        if (nullOrUndefined(taskInProgress))
            return null;
        return (
            <div ref={this.boxRef}>
                <div style={{marginLeft: 10}}>
                    <FormattedMessage id="inProgress"/>
                </div>
                {this.renderTaskList([taskInProgress])}
            </div>
        )
    }

    openDocumentsDialog = id => {
    }
    changeSelected = id => {
    }

    renderTaskList(taskList) {
        return taskList.map(
            task =>
                <Task clientMode={false}
                      openDocumentsDialog={this.openDocumentsDialog}
                      changeSelected={this.changeSelected}
                      generateRoutePointMessage={this.state.generateRoutePointMessage}
                      isSelected={this.state.selectedTask === task.position.routePointId}
                      isOpened={this.state.openedTask === task.position.routePointId}
                      documentDialogId={this.state.vehicleTasks.documentDialogId}
                      key={task.position.routePointId}
                      language={this.state.language}
                      clientSettings={this.state.clientSettings}
                      editTask={this.state.editTask}
                      editTaskDialog={this.state.editTaskDialog}
                      bdf={{render: this.state.vehicleTasks.canTransportContainers}}
                      selectedRouteId={this.state.selectedRouteId}
                      history={this.props.history}
                      truckId={this.truckId}
                      {...task} />
        );
    }

    handleFutureTaskToggle() {
        let {futureCategoryExpanded} = this.state;
        this.setState({futureCategoryExpanded: !futureCategoryExpanded});

    }

    renderFutureTasks(taskList) {
        if (taskList.length < 1)
            return null;

        let filteredTasks = taskList;
        let firstLoadOrUnload = -1;

        let loadOrUnloadTaskCount = taskList.filter(task => task.position.type === 0 || task.position.type === 1).length;

        if (!this.state.futureCategoryExpanded) {
            for (let i = 0; i < taskList.length; i++) {
                let task = taskList[i];

                if (task.position.type === 0 || task.position.type === 1) {
                    firstLoadOrUnload = i;
                    break;
                }
            }

            if (firstLoadOrUnload !== -1) {
                filteredTasks = [];

                for (let i = 0; i <= firstLoadOrUnload; i++) {
                    filteredTasks.push(taskList[i]);
                }
            }
        }

        return (
            <div>
                <TaskTitle
                    className={loadOrUnloadTaskCount > 1 ? 'is-collapsible' : ''}
                    onClick={() => loadOrUnloadTaskCount > 1 ? this.handleFutureTaskToggle() : null}>

                    <div style={{float: 'left', marginLeft:10}}>
                        <FormattedMessage id="inQueue"/>
                    </div>

                    <TaskBadge style={{float: 'left'}}>
                        {taskList.length}
                    </TaskBadge>

                    <div className={'toggle-button'} style={{float: 'left'}}>
                        {
                            this.state.futureCategoryExpanded ?
                                <i style={{paddingLeft: '4px'}}
                                   className={'fa fa-angle-up'}/> :
                                <i style={{paddingLeft: '4px'}}
                                   className={'fa fa-angle-down'}/>
                        }
                    </div>
                </TaskTitle>

                {this.renderTaskList(filteredTasks)}
            </div>
        )
    }

    render() {
        if (this.state.routePlanList == null) return null;
        if (this.store.allTrucks.length === 0) return null;
        if (this.state.vehicleTasks == null) return null;

        const {valstnr, eventsContainer, tlf, priekaba} = this.store.getVehicle(this.truckId);

        const {classes} = this.props;
        const {data} = this.state.routePlanList

        return (
            <div className={classes.root}>
                <MyAppBar/>
                <div style={{textAlign: 'center', marginTop: 10}}>
                    <div>
                    <span style={{paddingRight: '6px'}}
                          className={'fa fa-car'}/> {valstnr}
                        {this.renderKilometersToFinish()}
                        <br/>
                        Vairuotojas: {this.state.driverFullName}
                    </div>

                    <Select value={this.state.selectedRouteId}
                            onChange={this.onRouteChange}
                            className={classes.select}>

                        {data.map(m => (
                                <MenuItem key={m.id}
                                          value={m.id}>id: {m.id + ", nr: " + m.orderNumber}
                                </MenuItem>
                            )
                        )}
                    </Select>
                </div>

                <div className={classes.root}>
                    {this.renderTasks()}
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(VehicleTasks);