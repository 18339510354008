import jwtDecode from "jwt-decode";
import http from "./httpService";
import React from "react";


const apiEndpoint = http.apiUrl + "/auth/signin/";
const companyConfigUrl = http.apiUrl + "/auth/getCompanyConfig/";
const tokenKey = "token";

http.setJwt(getJwt());

export async function login(usernameOrEmail, password, companyId) {
    const {data: jwt} = await http.post(apiEndpoint, {
        usernameOrEmail,
        password,
        companyId
    });
    localStorage.setItem(tokenKey, jwt);
}

export async function getCompanyConfig() {
    return http.get(companyConfigUrl);
}

export function loginWithJwt(jwt) {
    localStorage.setItem(tokenKey, jwt);
}

export function logout() {
    localStorage.removeItem(tokenKey);
    window.location.reload();
}

export function getCurrentUser() {
    try {
        const jwt = localStorage.getItem(tokenKey);
        return jwtDecode(jwt);
    } catch (ex) {
        return null;
    }
}

export function getJwt() {
    return localStorage.getItem(tokenKey);
}

export default {
    login,
    loginWithJwt,
    logout,
    getCurrentUser,
    getJwt,
    getCompanyConfig
};
