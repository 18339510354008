import React, {Component} from "react";
import {CssBaseline, Paper} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import logo from "./img/logo.png";

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    avatar: {
        margin: theme.spacing(),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(),
    },
    submit: {
        marginTop: theme.spacing(3),
    },
});

class Offline extends Component {

    render() {
        const {classes} = this.props;

        return (<div>
            <main className={classes.main}>
                <CssBaseline/>
                <Paper className={classes.paper}>
                    <img width='100%' src={logo}/>
                    <div style={{textAlign: 'center', color: 'rgb(39, 91, 138)', fontSize: '1rem', fontWeight: 'bold'}}>
                        <p>Atsiprašome, šis puslapis nebepasiekiamas.</p>
                        <p>Norėdami prisijungti prie mobilios FleetMaster versijos, naršyklės lange įveskite savo įmonės
                            FleetMaster adresą, pvz.: https://cloud.fleetmaster.lt/LAT-XX</p>
                    </div>
                </Paper>
            </main>
        </div>);
    }
}

export default withStyles(styles)(Offline);
