import {observable} from "mobx";
import {
    getGroups,
    getManagers,
    getVehicles
} from "../services/driversTableService";
import {toast} from "react-toastify";
import {getActiveRoute} from "../services/routesService"
import {persist} from "mobx-persist";

class DriverTableStore {

    constructor(rootStore) {
        this.rootStore = rootStore
    }

    @observable isLoading = false;
    @observable managers = [];
    @observable groups = [];
    @observable allTrucks = [];
    @observable filteredTrucks = [];
    @persist @observable selectedManager = "all";
    @persist @observable selectedGroup = "all";


    async loadVehicles() {

        let groups = await getGroups();
        let managers = await getManagers();
        let trucks = await this.loadTrucks(this.selectedManager, this.selectedGroup);

        this.groups = groups.data;
        this.managers = managers.data.managers;
        this.allTrucks = trucks.data.vehicles;
        this.filteredTrucks = trucks.data.vehicles;

    }

    async loadActiveRoute(vehicleId) {
        let activeRoute = await getActiveRoute(vehicleId);

    }

    handleFilterChange = event => {
        let filterValue = event.target.value.toLowerCase();

        if (filterValue.empty) {
            this.filteredTrucks = this.allTrucks;
            return;
        }
        this.filteredTrucks = this.allTrucks
            .filter(v => v.valstnr.toLowerCase().includes(filterValue))
    }

    handleGroupChange = async (event) => {
        let groupId = event.target.value;
        let managerId = this.selectedManager;
        let trucks = await this.loadTrucks(managerId, groupId);

        this.selectedGroup = event.target.value;
        this.allTrucks = trucks.data.vehicles;
        this.filteredTrucks = trucks.data.vehicles;
    }

    handleManagerChange = async (event) => {

        let managerId = event.target.value;
        let groupId = this.selectedGroup;
        let trucks = await this.loadTrucks(managerId, groupId);

        this.selectedManager = managerId;
        this.allTrucks = trucks.data.vehicles;
        this.filteredTrucks = trucks.data.vehicles;
    }

    handleRefresh = async () => {
        let managerId = this.selectedManager;
        let groupId = this.selectedGroup;
        let trucks = await this.loadTrucks(managerId, groupId);

        this.allTrucks = trucks.data.vehicles;
        this.filteredTrucks = trucks.data.vehicles;
        // toast.info("Atnaujinta");
        
    }

    loadTrucks(managerId, groupId) {
        managerId = managerId === "all" ? "" : managerId;
        groupId = groupId === "all" ? "" : groupId;
        let vehicles = getVehicles(managerId, groupId);
        return vehicles;
    }

    getVehicle(id) {
        let tr = this.allTrucks.find(v => v.idTrpr == id);
        return tr;
    }
}

export default DriverTableStore;