import * as React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
    formControl: {
        margin: 1,
        minWidth: 120,
    },
});

class ManagerSelect extends React.Component {
    render() {
        const {managers} = this.props;
        return (
            <FormControl className={styles.formControl}>
                <InputLabel htmlFor="manager">
                <FormattedMessage id="manager" />
                </InputLabel>
                <Select value={this.props.value} onChange={this.props.onChange}>
                    <MenuItem value={"all"}>Visi</MenuItem>
                    {managers.map(m => (
                            <MenuItem key={m.idDrb} value={m.idDrb}>{m.fullName}</MenuItem>
                        )
                    )}
                </Select>
            </FormControl>
        );
    };
};

export default  ManagerSelect