import styled from 'styled-components';

export const TruncatedText = styled.p`
    flex-grow: 0;
    margin: 0;
    // max-width: 200px;
    overflow: hidden;
    padding: 0;
    text-overflow: ellipsis;
    // white-space: nowrap;
`;

export const IconButton = styled.i`
    display: block;
    line-height: 1.5;
`;