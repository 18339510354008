import React, { Component } from 'react';

import { validTypeIcons, validTypeValues } from "../routePointTypes";
import KmsComponent from "./KmsComponent";
import { getTranslation, getCurrentPageLanguage } from '../utils';

export default class LeftKmsPanelComponent extends Component {

    constructor(props) {
        super(props);
        this.state = { hidden: true };
    }

    show() {
        this.setState({ hidden: false });
        if (this.props.toggleKmsPanelHandler) {
            this.props.toggleKmsPanelHandler(this.props.idTrpr, true);
        }
    }

    hide() {
        this.setState({ hidden: true });
        if (this.props.toggleKmsPanelHandler) {
            this.props.toggleKmsPanelHandler(this.props.idTrpr, false);
        }
    }

    onClick(e) {
        if (!e.target.classList.contains("kms-toggle")) {
            e.stopPropagation();
        }
    }

    onKmsToggle(e, idTrpr, kms) {
        e.stopPropagation();
        this.props.toggleHandler(idTrpr, kms);
    }

    render() {
        let visible = this.props.visible;
        if (!visible) {
            this.state.hidden = true;
        }
        if (!this.state.hidden) {
            let parentPosition = this.props.parent.getBoundingClientRect();
            let clientMode = this.props.clientMode;
            let topPosition = parentPosition.top-(clientMode ? 61 : 121);
            let data = this.props.data;
            let leftKmsList = data.map((leftKms) =>
                <KmsComponent data={leftKms} hidden={this.props.hiddenKms.includes(leftKms.index) || this.props.hiddenWayPointTypes.includes(leftKms.wayPointType)} />
            );
            let showToggle = (kms) => {
                if (this.props.hiddenWayPointTypes.includes(kms.wayPointType)) {
                    return (
                        <i class="fa fa-gear kms-toggle" style={{ 'opacity': '0.5' }} title={getTranslation('drivers-table.kms.hidden-by-settings')} />
                    );
                } else if (this.props.hiddenKms.includes(kms.index)) {
                    return (
                        <i class="fa fa-eye-slash kms-toggle" onClick={(e) => this.onKmsToggle(e, this.props.idTrpr, kms)} />
                    );
                } else {
                    return (
                        <i class="fa fa-eye kms-toggle" onClick={(e) => this.onKmsToggle(e, this.props.idTrpr, kms)} />
                    );
                }
            };            
            let lastKmsIdx = data.length - 1;
            let leftKmsShowList = data.map((leftKms, idx) =>
                <div style={{ 'display': 'table-cell', 'text-align': 'center', 'padding-left': '8px' }}>{idx === lastKmsIdx ? <i class="fa fa-fw fa-close" onClick={(e) => this.hide()} /> : showToggle(leftKms)}</div>
            );
            let leftKmsTypeList = data.map((leftKms) =>
                <div style={{ 'display': 'table-cell', 'text-align': 'center', 'padding-left': '8px', 'opacity': (this.props.hiddenKms.includes(leftKms.index) || this.props.hiddenWayPointTypes.includes(leftKms.wayPointType) ? '0.5' : '1') }}>
                    <i class={'fa ' + validTypeIcons[leftKms.wayPointType]} title={validTypeValues[getCurrentPageLanguage()][leftKms.wayPointType]} />
                </div>
            );
            return (
                <div class="more-overlay" onClick={(e) => this.onClick(e)} style={{ 'display': 'block', 'top': `${topPosition}px` }}>
                    <div class="kms-type" style={{ 'display': 'table-row' }}>
                        {leftKmsTypeList}
                    </div>
                    <div class="kms-item" style={{ 'display': 'table-row' }}>
                        {leftKmsList}
                    </div>
                    <div class="visibility-toggles" style={{ 'display': 'table-row' }}>
                        {leftKmsShowList}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

}