import React, {Component} from 'react';
import {getTranslation} from "../../../utils";

class ActionComponent extends Component {
    constructor(props) {
        super(props);
        this.renderContent = this.renderContent.bind(this);
    }

    renderContent() {
        let {action, dateLine, color, duration, type, comment, actionType} = this.props;
        let arrowDirection = type == 1 ? "fa fa-arrow-left driverStateAlign" : "fa fa-arrow-right driverStateAlign";
        return (
            <div style={{color: color, width: '78px'}}
                 title={this.getActionLoadingStateTitle(type == 0, actionType, comment)}>
                <div style={{'textAlign': 'center', width: '100%'}}>
                        <span style={{'verticalAlign': 'text-bottom'}}>
                            <i className={arrowDirection}
                               style={{
                                   'fontSize': '0.9em', 'paddingRight': '8px'
                               }}/>
                        </span>
                    <i className="fa fa-truck fa-flip-horizontal driverStateAlign"
                       style={{'paddingLeft': '7px'}}/>
                    <span style={{
                        'verticalAlign': 'text-bottom',
                        'fontSize': '0.8em'
                    }}>
                            {duration}
                        </span>
                </div>
                <span style={{
                    'verticalAlign': 'text-bottom',
                    'fontSize': '0.8em'
                }}>
                    {dateLine}
                </span>
            </div>
        );
    }

    getActionLoadingStateTitle(loading, actionLoading, actionLoadingComment) {
        //loading: true - loading, false - unloading
        switch (actionLoading) {
            case 0: //NENURODYTA
                return '';
            case 2: //KRAUNA
                return (loading ? getTranslation('drivers-table.driver-state.loading') : getTranslation('drivers-table.driver-state.unloading')) +
                    (actionLoadingComment ? ", " + actionLoadingComment : "");
            case 3: //PAKROVE
                return (loading ? getTranslation('drivers-table.driver-state.loaded') : getTranslation('drivers-table.driver-state.unloaded')) +
                    (actionLoadingComment ? ", " + actionLoadingComment : "");
            case 4: //LAUKTI PARKINGE
                return (getTranslation('drivers-table.driver-state.not-loading') + ", " + getTranslation('drivers-table.driver-state.not-loading.wait-parking')) +
                    (actionLoadingComment ? ", " + actionLoadingComment : "");
            case 5: //EILE
                return getTranslation('drivers-table.driver-state.not-loading') + ", " +
                    getTranslation('drivers-table.driver-state.not-loading.in-line') + (actionLoadingComment ? " " + actionLoadingComment : "");
            case 6: //GAUTAS PAGER
                return (getTranslation('drivers-table.driver-state.not-loading') + ", " + getTranslation('drivers-table.driver-state.not-loading.received-pager')) +
                    (actionLoadingComment ? ", " + actionLoadingComment : "");
            case 7: //FIKSUOTAS LAIKAS
                return (getTranslation('drivers-table.driver-state.not-loading') + ", " + getTranslation('drivers-table.driver-state.not-loading.fixed-time')) +
                    (actionLoadingComment ? ", " + actionLoadingComment : "");
            case 8: //KRAUS RYTOJ
                return (getTranslation('drivers-table.driver-state.not-loading') + ", " + getTranslation('drivers-table.driver-state.not-loading.load-tomorrow')) +
                    (actionLoadingComment ? ", " + actionLoadingComment : "");
            case 9: //KITA
                return getTranslation('drivers-table.driver-state.not-loading') + ", " +
                    getTranslation('drivers-table.driver-state.not-loading.other') + (actionLoadingComment ? " " + actionLoadingComment : "");
            default:
                return '';
        }
    }

    render() {
        return (
            <div>
                {this.renderContent()}
            </div>
        );
    }
}

export default ActionComponent;
