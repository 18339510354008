import React, {Component} from 'react';
import {IconButton, TruncatedText} from "./CommentStyle";
import {getTranslation} from "../../../utils";

class CommentComponent extends Component {
    constructor(props) {
        super(props);

        this.renderContent = this.renderContent.bind(this);
        this.getClassName = this.getClassName.bind(this);
    }

    renderContent() {
        let {comment, titleId, icon} = this.props;

        return (
            <div style={{display: 'flex'}}
                 className={this.getClassName()}>
                <IconButton title={getTranslation(titleId)}
                            style={{flexGrow: 0, paddingRight: '5px'}}
                            className={`${this.getClassName()} fa ${icon}`} />

                <TruncatedText title={comment}>{comment}</TruncatedText>
            </div>
        );
    }

    getClassName() {
        return `task__date route-point-id-${this.props.routePointId}`;
    }

    render() {
        return (
            <div className={this.getClassName()}>
                {this.renderContent()}
            </div>
        );
    }
}

export default CommentComponent;
