import React from 'react';
import PropTypes from "prop-types";

import {TruncatedText, Value} from "./RefNumberStyle";
import {getTranslation} from "../../../utils";

const RefNumberComponent = ({refNumber, routePointId}) => {
    return (
        <div style={{display: 'flex'}}
             className={`route-point-id-${routePointId}`}>
            <Value className={`route-point-id-${routePointId}`}>
                {getTranslation('referenceNr') + ': '}
            </Value>
            <TruncatedText title={refNumber}
                           className={`route-point-id-${routePointId}`}>
                {refNumber}
            </TruncatedText>
        </div>
    )
};

RefNumberComponent.propTypes = {
    routePointId: PropTypes.number,
    refNumber: PropTypes.string,
    loadingLabel: PropTypes.string,
};

export default RefNumberComponent;