import React, {Component} from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import '../styles/index1.css';
import '../styles/technicalCheckups.css';

import MyAppBar from "./common/myAppBar";
import UpdateTimer from "./common/updateTimer"
import DriverStateComponent from "./vehicle/DriverStateComponent";
import LeftKmsComponent from "./vehicle/LeftKmsComponent";
import NotesComponent from "./vehicle/notesComponent";
import ManagerSelect from "./vehicle/managerSelect";
import GroupSelect from "./vehicle/groupSelect";
import Box from "@material-ui/core/Box";
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import InputBase from "@material-ui/core/InputBase";
import {withStyles} from "@material-ui/core";
import {fade} from "@material-ui/core/styles";
import {getTranslation} from "./utils";
import {inject, observer} from "mobx-react";

const styles = theme => ({
    root: {},
    table: {},
    textField: {
        color: "red",
        marginLeft: 10
    },
    input: {
        color: "white"
    },
    formControl: {
        margin: 1,
        minWidth: 120,
    },
    managerSelect: {},

    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },

});

const ReminderBadge = (props) => (
    <span style={{top: props.second ? '3px !important' : ''}}
          className={'badge-checkup-reminders ' + (props.second ? 'checkupToBottom' : '')}>
                                {props.reminders}
                            </span>);


@inject('rootStore')
@observer
class TruckList extends Component {

    store = this.props.rootStore.driverTableStore;

    handleOnRowClick = id => {
        this.props.history.push("/truck/" + id);

    }

    handleOnInfoClick = id => {
        this.props.history.push("/vehicleTasks/" + id);
    }

    toggleNotesListPanel(idTrpr, e) {
        console.log(idTrpr)
    }

    buttonTechnicalCheckupTemplate(rowData, trailer) {
        let plannedMarker = (
            <i className="fa fa-map-marker plannedMarker bounce}"
               style={{
                   'position': 'absolute',
                   'top': '-3px',
                   'left': '0',
                   'fontSize': '1.2em',
                   'color': '#03b912',
                   'pointerEvents': 'none'
               }}/>
        );
        return (
            <div>
                <div
                    className={`technicalCheckupStatus-${rowData.technicalCheckupStatus ? rowData.technicalCheckupStatus : rowData.technicalCheckupStatusTrailer}`}>
                    <div style={{
                        'display': 'inline-block',
                        'position': 'relative'
                    }}
                         onClick={(e) => this.onTechnicalCheckupButtonClick(e, rowData, trailer)}
                         className="technicalCheckupContainer ">
                        <div className="technicalCheckupCommand">
                            <i style={{'fontSize': '1.3em'}}
                               className="fa fa-fw fa-wrench"
                               title={getTranslation('drivers-table.technical-checkup')}/>
                        </div>
                        {trailer ? rowData.plannedTechnicalCheckupsCntTrailer > 0 && plannedMarker : rowData.plannedTechnicalCheckupsCnt > 0 && plannedMarker}
                        {trailer ? rowData.technicalCheckupsReminderCntTrailer > 0 &&
                            <ReminderBadge
                                reminders={rowData.technicalCheckupsReminderCntTrailer}/> : rowData.technicalCheckupsReminderCnt > 0 &&
                            <ReminderBadge
                                reminders={rowData.technicalCheckupsReminderCnt}/>}
                        {!trailer ? rowData.technicalCheckupsReminderCntTrailer > 0 &&
                            <ReminderBadge second={true}
                                           reminders={rowData.technicalCheckupsReminderCntTrailer}/> : ''}
                    </div>
                </div>
            </div>
        );
    }

    buttonChatTemplate(rowData) {
        let notReadBadge = (
            <span className="badge-not-read"
                  id={`driversTable-not-read-${rowData.idTrpr}`}>{rowData.messagesInfo.notReadCnt}</span>
        );
        let notDeliveredBadge = (
            <span className="badge-not-delivered"
                  id={`driversTable-not-delivered-${rowData.idTrpr}`}>{rowData.messagesInfo.notDeliveredCnt}</span>
        );
        let notReceivedBadge = (
            <span className="badge-not-received"
                  id={`driversTable-not-received-${rowData.idTrpr}`}>{rowData.messagesInfo.notReceivedCnt}</span>
        );
        return (
            <div className="icon-badge-wrapper"
                 onClick={(e) => this.onChatButtonClick(e, rowData)}>
                <i style={{'color': 'black', 'fontSize': '1.3em'}}
                   className="fa fa-fw fa-envelope"
                   title={getTranslation('drivers-table.messages')}/>
                {rowData.messagesInfo.notReadCnt > 0 && notReadBadge}
                {rowData.messagesInfo.notDeliveredCnt > 0 && notDeliveredBadge}
                {rowData.messagesInfo.notReceivedCnt > 0 && notReceivedBadge}
            </div>
        );
    }

    buttonFuelIndicatorTemplate(rowData) {
        let fuelIcon;
        fuelIcon = (
            <div style={{'height': '9px', 'lineHeight': '1'}}>
                <i className="fa fa-fw fa-tint" style={{'fontSize': '1.0em'}}/>
            </div>
        );
        let fuelLevel;
        fuelLevel = (
            <div style={{'height': '12px', 'lineHeight': '1'}}
                 title={`${getTranslation('drivers-table.fuel')} - ${rowData.fuelLevel} l`}>
                <span style={{'fontSize': '10px'}}>
                    {rowData.fuelLevel}
                </span>
            </div>
        );
        return (
            <div style={{
                'flexShrink': '0',
                'flexGrow': '0',
                'flexBasis': '24px',
                'color': rowData.isFuelAlert && !rowData.isFuelPlanned ? 'red' : null
            }}>
                {fuelIcon}
                {fuelLevel}
            </div>
        );
    }

    buttonInfo(rowData) {
        let fuelIcon = (
            <div style={{'height': '9px', 'lineHeight': '1'}}>
                <i className="fa fa-info-circle" style={{'fontSize': '1.3em'}}/>
            </div>
        );
        return (
            <div style={{
                'flexShrink': '0',
                'flexGrow': '0',
                'flexBasis': '34px',
                'color': rowData.isFuelAlert && !rowData.isFuelPlanned ? 'red' : null
            }}
            >
                {fuelIcon}
            </div>
        );
    }


    render() {
        const {
            managers, selectedManager,
            filteredTrucks, selectedGroup, groups
        } = this.store;

        const {classes} = this.props;

        const rowStyle = {height: "35px", padding: "5px"};
        return (
            <Paper className={classes.root}>

                <MyAppBar>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon/>
                        </div>
                        <InputBase
                            placeholder="Search…"
                            onChange={this.store.handleFilterChange}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{'aria-label': 'Search'}}
                        />
                    </div>
                    <UpdateTimer interval='30'
                                 onUpdate={this.store.handleRefresh}/>
                </MyAppBar>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Box>
                                    <ManagerSelect
                                        value={selectedManager}
                                        onChange={this.store.handleManagerChange}
                                        managers={managers}
                                        className={classes.managerSelect}/>
                                </Box>
                                <Box>
                                    <GroupSelect
                                        value={selectedGroup}
                                        onChange={this.store.handleGroupChange}
                                        groups={groups}/>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredTrucks.map(row => (
                            <TableRow key={row.idTrpr}
                                      style={rowStyle}>

                                <TableCell style={rowStyle}
                                           onClick={() => this.handleOnRowClick(row.idTrpr)}>
                                    {row.valstnr}
                                </TableCell>

                                <TableCell style={rowStyle}
                                           onClick={() => this.handleOnRowClick(row.idTrpr)}>
                                    <div
                                        className={`truck-info technicalCheckupStatus-${row.technicalCheckupStatus}`}>
                                        <div className="truck-info">
                                            {/*<NotesComponent data={row}*/}
                                            {/*                onClickEvent={(e) => this.toggleNotesListPanel(row.idTrpr, e)}/>*/}
                                            <DriverStateComponent
                                                onClick={() => this.handleOnRowClick(row.idTrpr)}
                                                data={row}/>
                                            <LeftKmsComponent
                                                onClick={() => this.handleOnRowClick(row.idTrpr)}
                                                data={row}
                                                toggleHandler={this.toggleKmsVisibility}
                                                showAllRoutePoints={true}
                                                toggleKmsPanelHandler={this.toggleKmsPanel}
                                                hiddenWayPointTypes={false}
                                                clientMode={false}/>
                                        </div>

                                        <div className="truck-controls"
                                             style={{
                                                 'display': 'flex',
                                                 'flexDirection': 'row-reverse',
                                                 'position': 'relative'
                                             }}>

                                            {/*{this.buttonChatTemplate(row)}*/}
                                            {/*{this.buttonTechnicalCheckupTemplate(row, false)}*/}
                                            {this.buttonFuelIndicatorTemplate(row)}
                                        </div>
                                    </div>
                                </TableCell>

                                <TableCell style={rowStyle}
                                           onClick={() => this.handleOnInfoClick(row.idTrpr)}>
                                    {this.buttonInfo(row)}

                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        );
    };

};

export default withStyles(styles)(TruckList);