import React from 'react';
import PropTypes from 'prop-types';

import {Value} from '../row/RowStyle';
import {nullOrUndefined, formatTitleAndAddress} from "../../../utils";

const getAddressLineValue = ({address, name, postalCode, city, country, countryCode}) => {
    return formatTitleAndAddress(name, address, postalCode, city, country, countryCode)
};

const AddressComponent = ({onClick, position, icon}) => {
    return (
        <div className={`task__address route-point-id-${position.routePointId}`}
             style={onClick ? {cursor: 'pointer'} : null} onClick={onClick}>
            <Value className={`route-point-id-${position.routePointId} is-bold`}>
                {!nullOrUndefined(icon) ? <i style={{paddingRight: '5px'}} className={`fa ${icon}`}/> : null}
                {getAddressLineValue(position)}
            </Value>
        </div>
    );
};

AddressComponent.propTypes = {
    position: PropTypes.object,
    icon: PropTypes.string,
    onClick: PropTypes.func
};

export default AddressComponent;