import styled from "styled-components";

export const Value = styled.p`
    font-size: 12px;
    margin: 0;
    padding: 0 4px 0 0;

    &.is-bold {
      font-weight: 700;
    }
`;

export const TruncatedText = styled.p`
    font-size: 12px;
    margin: 0;
    overflow: hidden;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const IconButton = styled.i`
    cursor: pointer;
    display: block;
    line-height: 1.5;
    padding-left: 4px;
`;