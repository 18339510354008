import {IntlProvider} from 'react-intl';
import messages_en from "../i18n/en.json";
import messages_lt from "../i18n/lt.json";
import messages_ru from "../i18n/ru.json";

const messages = {
    'en': messages_en,
    'lt': messages_lt,
    'ru': messages_ru
};

const {intl} = new IntlProvider({
    locale: 'en',
    messages: messages['lt'],
}, {}).getChildContext();


export const nullOrUndefined = (value) => {
    return value === null || value === undefined;
};

export const getCurrentPageLanguage = () => {
    const html = document.getElementsByTagName('html')[0];

    return html.lang;
};


export const getTranslation = (id) => {

    return intl.formatMessage({id});
}


// export const getTranslation = (translationId) => {
//     return (
//         <FormattedMessage id={translationId} />
//     );
// };

export const formatTitleAndAddress = (title, address, postCode, city, country, countryCode) => {
    return joinStrings(". ", title, formatAddress(address, postCode, city, country, countryCode));
}

function joinStrings(separator, str1, str2) {
    return joinStringsArray(separator, [str1, str2]);
}

function formatAddress(address, postCode, city, country, countryCode) {
    var formattedPostCode = formatPostCode(postCode, countryCode);
    var postCodeAndCity = joinStrings(" ", formattedPostCode, city);
    var text = joinStrings(", ", address, postCodeAndCity);
    text = joinStrings(", ", text, country);
    return text;
}

function formatPostCode(postCode, countryCode) {
    return countryCode !== null && postCode !== null
        ? countryCode + '-' + postCode
        : (postCode || "");
}

function joinStringsArray(separator, args) {
    var text = "";
    for (var i in args) {
        if (args[i] !== undefined && args[i] !== null && args[i].toString().length > 0) {
            if (text.length !== 0) {
                text += separator;
            }
            text += args[i];
        }
    }
    return text;
}

export const initializePolyfills = () => {
    if (typeof Object.assign !== 'function') {
        Object.defineProperty(Object, "assign", {
            value: function assign(target, varArgs) {
                'use strict';
                if (target == null) {
                    throw new TypeError('Cannot convert undefined or null to object');
                }

                var to = Object(target);

                for (var index = 1; index < arguments.length; index++) {
                    var nextSource = arguments[index];

                    if (nextSource != null) { // Skip over if undefined or null
                        for (var nextKey in nextSource) {
                            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                                to[nextKey] = nextSource[nextKey];
                            }
                        }
                    }
                }
                return to;
            },
            writable: true,
            configurable: true
        });
    }

    if (!Array.prototype.forEach) {
        Array.prototype.forEach = function (callback/*, thisArg*/) {
            var T, k;

            if (this == null) {
                throw new TypeError('this is null or not defined');
            }

            var O = Object(this);

            var len = O.length >>> 0;

            if (typeof callback !== 'function') {
                throw new TypeError(callback + ' is not a function');
            }

            if (arguments.length > 1) {
                T = arguments[1];
            }

            k = 0;

            while (k < len) {
                var kValue;

                if (k in O) {
                    kValue = O[k];
                    callback.call(T, kValue, k, O);
                }
                k++;
            }
        };
    }
};

