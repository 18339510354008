import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import {Value} from '../row/RowStyle';
import {getTranslation, nullOrUndefined} from "../../../utils";

class PlannedDateComponent extends Component {
    constructor(props) {
        super(props);

        this.getRoutePointDate = this.getRoutePointDate.bind(this);
        this.getRouteAccountingPlanDate = this.getRouteAccountingPlanDate.bind(this);
    }

    getTaskDate() {
        let {planTime} = this.props.routeAccounting;

        return !nullOrUndefined(planTime) ? moment.tz(planTime, this.props.timezone).format('YYYY-MM-DD HH:mm') : null;
    }

    getRouteAccountingPlanDate() {
        let date = this.getTaskDate();

        return nullOrUndefined(date) ? null : `${getTranslation('plannedDateTitle')} ${date}`;
    }

    getRoutePointDate() {
        let {routePointId} = this.props.position;

        return (
            <div className={`task__date route-point-id-${routePointId}`}>
                {this.getRouteAccountingPlanDate()}
            </div>
        );
    }

    render() {
        let {routePointId} = this.props.position;

        return (
            <div className={`task__date route-point-id-${routePointId}`}>
                <Value className={`route-point-id-${routePointId}`}>
                    {this.getRoutePointDate()}
                </Value>
            </div>
        )
    }
}

PlannedDateComponent.propTypes = {
    routeAccounting: PropTypes.object,
    position: PropTypes.object,
    vehicleTaskType: PropTypes.number,
};

export default PlannedDateComponent;